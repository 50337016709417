import "./CheckBox.css"

const CheckBox = ({ isCalling, isFetching ,checked, setChecked }) => {

    return (
        <>
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="checkbox-input"
                checked={checked}
                disabled={isFetching || isCalling}
                onChange={()=>setChecked(!checked)}
              />
              <span className="checkbox-text">I'm legally able to purchase ammunition. <a className="terms-text" href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">See terms</a></span>
            </label>
        </>
        );
};

export {CheckBox}; 