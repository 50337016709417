import { useCallback, useEffect } from "react";
import { ProductFormView } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/ProductForm/ProductForm.componentView";
import { ProductFormProps } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/ProductForm/ProductForm.componentModel";
import { useComponentModel } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/ProductForm/ProductForm.componentService";
import { Quote } from "@ultracommerce/react-storefront/global/src/interface/Quote";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { useDispatch } from 'react-redux'
import { addToCartOrQuote } from '../../../../actions/cartActions'
import { useState } from 'react'
import { useSelector } from "react-redux";
import {Modal} from '../../../../components/Modal/Modal'
import { useNavigate, Link } from 'react-router-dom'
import { getProductRoute } from "@ultracommerce/react-storefront/global";
import { Sku } from "@ultracommerce/react-storefront/src/interface/Product";
const ProductForm = (props: ProductFormProps) => {
  const { componentData, componentService } = useComponentModel(props);
  const { ProductModule, CommonModule: { SimpleImage }, } = useElementContext();
  const [updateQtyModal, setUpdateQtyModal] = useState<any>(false);
  const [itemData, setItemData] = useState<any>({})
  const [updateQtyError, setUpdateQtyError] = useState<any>("")
  const navigate = useNavigate();
  const productRouting = useSelector(getProductRoute);
  const { getAllCartOrQuotes } = componentService;
  useEffect(() => {
    if (componentData.authenticationRequiredForAddToCart) return;
    getAllCartOrQuotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentData.authenticationRequiredForAddToCart, getAllCartOrQuotes]);
  const skuConfiguration = props.skuConfiguration;
  const dispatch = useDispatch()
  const addToQuote = useCallback(
    (sku: Sku, qty: number, quote?: Quote, skuConfiguration?: any) => {
      dispatch<any>(addToCartOrQuote(sku, qty, quote?.orderID, quote?.quoteName, skuConfiguration)).then((response: any) => {
        getAllCartOrQuotes();
        if(Object.values(response?.success()?.cart?.errors).length){
        //@ts-ignore
        setUpdateQtyError(Object.values(response?.success()?.cart?.errors)[0][0])
        setUpdateQtyModal(true)
        setItemData(props)
      }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, getAllCartOrQuotes],
  );

  const addToCartOrQuoteTwo = useCallback(
    (quote?: Quote) => {
      if (componentData.isLoading) return;
      addToQuote(
        componentData.sku,
        componentData.itemCount,
        quote,
        skuConfiguration,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      componentData.isLoading,
      componentData.itemCount,
      componentData.sku.skuID,
      componentData.sku.sku_skuID,
      componentService,
      skuConfiguration,
    ],
  );

  return (
    <>
    <ProductFormView viewData={componentData} {...componentService} addToCartOrQuote={addToCartOrQuoteTwo} /> 
    <Modal show={updateQtyModal} setShow={setUpdateQtyModal} size="large" title="These item(s) may not have been added" backgroundstatic={true}>
      { 
        updateQtyError && <h3 className="bg-light text-danger d-flex text-center align-items-center p-3">{updateQtyError}</h3>
      }
      
      
        {itemData &&
          <div className="row py-3 cart-item-box">
            { itemData.sku && <div className="row detail">
              <div className="col-sm-2 col-3 image">
                {itemData?.sku?.imageFile && (
                  <SimpleImage
                    className="img-fluid  m-auto image_container productImage"
                    src={itemData?.sku?.imageFile}
                    alt={itemData?.sku?.product_productName}
                    type="product"
                  />
                )}
              </div>
              <div className="col-sm-4 col-9">
                <div className="title">
                  <h4>
                    <Link
                      to={{
                        pathname: `/${productRouting}/${itemData?.sku?.product_urlTitle}`,
                        // @ts-ignore
                        state: { ...itemData?.sku},
                      }}
                      className="text-decoration-none text-dark"
                    >
                      {itemData?.sku?.product_productName}
                    </Link>
                  </h4>
                </div>
                <div className="font-size-sm item-sku">
                  <span className="mr-2">SKU : {itemData?.sku?.skuCode}</span>
                </div>
                
               
              </div>
              <div className="col-sm-12 col-md-6 d-flex bottom-detail-box d-none d-md-block">
                <div className="row">
                  <div className="item-price col-sm-3">
                    <ProductModule.CoreComponents.ProductPrice
                      type="cart"
                      salePrice={itemData?.sku?.salePrice}
                      listPrice={itemData?.sku?.listPrice}
                    />
                  </div>
                </div>
              </div>
            </div>}
            
          </div>
        }
        <div className="d-flex justify-content-around mt-3"> 
          <button className="btn btn-primary mw-25 w-25" onClick={()=>setUpdateQtyModal(false)}>
            Continue Shopping
          </button>
          <button className="btn btn-primary mw-25 w-25" onClick={()=>navigate("/shopping-cart")}>
            Go to Cart 
          </button>
        </div>
    </Modal>
    </>
    );
};

export { ProductForm, type ProductFormProps };
