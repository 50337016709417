import { useState, useEffect } from "react";
import { axios } from "@ultracommerce/react-storefront/global";
import { useSelector } from "react-redux";

const serviceDomain = process.env.REACT_APP_API_URL;

export const useRestrictions = (type = null) => {
  let [data, setData] = useState({});
  const orderID = useSelector((state) => state.cart?.orderID)

  useEffect(() => {
    let source = axios.CancelToken.source();
   
    async function fetchRestrictions () {
        try{
          const servicePath = "/api/scope/getRestrictionsForItemsInCart";
          const {data} = await axios (serviceDomain + servicePath, {
            data: {"orderID": orderID},
            withCredentials: false,
            method: 'POST',
          }
          );
         
          setData(data);
        }
        catch(err){
          return "error";
        }
    }
    fetchRestrictions();
    return () => {
      source.cancel();
    };
  }, [orderID]);
  
  if(data?.errors){
    return "error";
  }
  if(type === "ifFFLRequiredPresent"){
    const fflRequired = data?.data?.orderItems.some((order)=>order?.toBeFFLShipped === true);
    const nonFFLRequired = data?.data?.orderItems.some((order)=>order?.toBeFFLShipped === false);
    return { fflRequired, nonFFLRequired };
  }
  return data?.data?.orderItems;
  
};