import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getCountriesAndAddressOptions } from "@ultracommerce/react-storefront/global";
import { getAllAccountAddresses, getDefaultCountry } from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { Modal } from "../../Modal/Modal";
import {useRestrictions} from '../../../hooks/components/Cart/useRestrictions'
import { useNavigate} from 'react-router-dom'
import { axios } from "@ultracommerce/react-storefront/global";
import { receiveCart } from "@ultracommerce/react-storefront/global";
import { getCart } from "@ultracommerce/react-storefront/global";
import {logout} from '@ultracommerce/react-storefront/global'

const BillingAddress = ({ onSave, onCancel, saveAddressOnAccount = true, existingAddress = {}, foidNumber="" }) => {
  
  const {
    CommonModule: { SwSelect },
  } = useElementContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const defaultCountryCode = useSelector(getDefaultCountry);
  const [disableSave, ] = useState(false);
  const [isEdit] = useState(true);
  const countries = useSelector((state) => state.content.countries);
  const [name, setName] = useState(existingAddress.name ?? "");
  const [countryCode, setCountryCode] = useState(existingAddress.countryCode ?? defaultCountryCode);
  const [stateCode, setStateCode] = useState(existingAddress.stateCode ?? "");
  const [postalCode, setPostalCode] = useState(existingAddress.postalCode ?? "");
  const [locality, setLocality] = useState(existingAddress.locality ?? "");
  const [city, setCity] = useState(existingAddress.city ?? "");
  const [streetAddress, setStreetAddress] = useState(existingAddress.streetAddress ?? "");
  const [street2Address, setStreet2Address] = useState(existingAddress.street2Address ?? "");
  const [phoneNumber, setPhoneNumber] = useState(existingAddress.phoneNumber ?? "");
  const [emailAddress, setEmailAddress] = useState(existingAddress.emailAddress ?? "");
  const [saveAddress, setSaveAddress] = useState("");
  const [accountAddressName, setAccountAddressName] = useState("");
  
  useEffect(() => {
    if (Object.keys(countries)?.length === 0) {
      dispatch(getCountriesAndAddressOptions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);
  
  // useEffect(()=>{
  //   if(stateCode){
  //     switch (stateCode) {
  //       case 'IL':
  //         if(!foidNumber){
  //           toast.error(CustomToastWithLink,  {
  //             autoClose: false
  //           })
  //           setDisableSave(true)
            
  //         }
  //         break;
  //       case 'AK':
  //         toast.error("Sorry!!! No products can be shipped to your State",  {
  //             autoClose: false
  //           })
  //         setDisableSave(true)
  //         break;
  //       case 'CA':
  //         const restricted = orderItems.map((orderItem)=>{
  //           if(orderItem.sku?.product?.produtName?.includes('AR')){
  //             return true
  //           }else{
  //             return false;
  //           }
  //         })
          
  //         for(var a  in restricted){
  //           if(a){
  //             toast.error("AR series equipments are not allowed in your state.",  {
  //                 autoClose: false
  //               })
  //               setDisableSave(true)
  //               break;
  //           }
  //         }
          
  //         break;
  //       default:
  //         if(disableSave){
  //           setDisableSave(false)
  //         }
  //         return;
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[stateCode])

  const { addressOptions = {}, states = [] } = countries[countryCode] || {};

  //set default state value, since we don't have default option
  if (states.length > 0 && stateCode === "") {
    setStateCode(states[0]["value"]);
  }

  return (
    <>
      <form>
        <div className="row mt-3">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="name">{t("frontend.account.name")}</label>
              <input
                disabled={!isEdit}
                className="form-control"
                type="text"
                id="name"
                required={true}
                value={name}
                onChange={(e) => {
                  e.preventDefault();
                  setName(e.target.value);
                }}
              />
              {/* {formik.errors.name && <span className="form-error-msg">{formik.errors.name}</span>} */}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="checkout-country">{t("frontend.account.countryCode")}</label>
              <SwSelect
                id="countryCode"
                disabled={!isEdit}
                value={countryCode}
                onChange={(e) => {
                  e.preventDefault();
                  setCountryCode(e.target.value);
                }}
                options={Object.keys(countries).map((country) => countries[country])}
              />
            </div>
          </div>
        </div>
        <div className="row">
          {(
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="streetAddress">Street address*</label>
                <input
                  disabled={!isEdit}
                  className="form-control"
                  required={addressOptions.streetAddressRequiredFlag}
                  type="text"
                  id="streetAddress"
                  value={streetAddress}
                  onChange={(e) => {
                    e.preventDefault();
                    setStreetAddress(e.target.value);
                  }}
                />
                {/* {formik.errors.streetAddress && <span className="form-error-msg">{formik.errors.streetAddress}</span>} */}
              </div>
            </div>
          )}
          {(
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="street2Address">Street Address 2</label>
                <input
                  disabled={!isEdit}
                  className="form-control"
                  required={addressOptions.street2AddressRequiredFlag}
                  type="text"
                  id="street2Address"
                  value={street2Address}
                  onChange={(e) => {
                    e.preventDefault();
                    setStreet2Address(e.target.value);
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-sm-6">
            { (
              <div className="form-group">
                <label htmlFor="city">City*</label>
                <input
                  disabled={!isEdit}
                  className="form-control"
                  required={addressOptions.cityRequiredFlag}
                  type="text"
                  id="city"
                  value={city}
                  onChange={(e) => {
                    e.preventDefault();
                    setCity(e.target.value);
                  }}
                />
                {/* {formik.errors.city && <span className="form-error-msg">{formik.errors.city}</span>} */}
              </div>
            )}
          </div>
          {states.length > 0 && (
            <div className="col-sm-3">
              <div className="form-group">
                <label htmlFor="stateCode">State*</label>
                <SwSelect
                  id="stateCode"
                  disabled={!isEdit}
                  required={addressOptions.stateCodeRequiredFlag}
                  value={stateCode}
                  onChange={(e) => {
                    e.preventDefault();
                    setStateCode(e.target.value);
                  }}
                  options={states}
                />
                {/* {formik.errors.stateCode && <span className="form-error-msg">{formik.errors.stateCode}</span>} */}
              </div>
            </div>
          )}
          
          {(states.length <= 0 &&
            <div className="col-sm-3">
              <div className="form-group">
                <label htmlFor="postalCode">State*</label>
                <input
                  disabled={!isEdit}
                  className="form-control"
                  type="text"
                  required={addressOptions.stateCodeRequiredFlag}
                  id="stateCode"
                  value={stateCode}
                  onChange={(e) => {
                    e.preventDefault();
                    setStateCode(e.target.value);
                  }}
                />
                {/* {formik.errors.postalCode && <span className="form-error-msg">{formik.errors.postalCode}</span>} */}
              </div>
            </div>
          )}

          {(
            <div className="col-sm-3">
              <div className="form-group">
                <label htmlFor="postalCode">PostalCode*</label>
                <input
                  disabled={!isEdit}
                  className="form-control"
                  type="text"
                  required={addressOptions.postalCodeRequiredFlag}
                  id="postalCode"
                  value={postalCode}
                  onChange={(e) => {
                    e.preventDefault();
                    setPostalCode(e.target.value);
                  }}
                />
                {/* {formik.errors.postalCode && <span className="form-error-msg">{formik.errors.postalCode}</span>} */}
              </div>
            </div>
          )}
        </div>
        {addressOptions.localityShowFlag && (
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="locality">{addressOptions.localityLabel}</label>
                <input
                  disabled={!isEdit}
                  className="form-control"
                  required={addressOptions.localityRequiredFlag}
                  type="text"
                  id="locality"
                  value={locality}
                  onChange={(e) => {
                    e.preventDefault();
                    setLocality(e.target.value);
                  }}
                />
                {/* {formik.errors.locality && <span className="form-error-msg">{formik.errors.locality}</span>} */}
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="emailAddress">{t("frontend.account.emailAddress")}</label>
              <input
                disabled={!isEdit}
                className="form-control"
                type="text"
                id="emailAddress"
                value={emailAddress}
                onChange={(e) => {
                  e.preventDefault();
                  setEmailAddress(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="phoneNumber">{t("frontend.account.phoneNumber")} </label>
              <input
                className="form-control"
                disabled={!isEdit}
                type="text"
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => {
                  e.preventDefault();
                  setPhoneNumber(e.target.value);
                }}
              />
              {/* {formik.errors.emailAddress && <span className="form-error-msg">{formik.errors.emailAddress}</span>} */}
            </div>
          </div>
        </div>

        <div className="row">
          {saveAddressOnAccount && (
            <div className="col-sm-6">
              <div className="form-group">
                <div className="custom-control custom-checkbox mt-1">
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    id="saveAddress"
                    checked={saveAddress}
                    onChange={() => {
                      setSaveAddress(!saveAddress);
                    }}
                  />
                  <label className="custom-control-label ms-1" htmlFor="saveAddress">
                    {t("frontend.account.save_to")}
                  </label>
                </div>
              </div>

              {/* only display nickname field if "save address" is checked */}
              {!!saveAddress && (
                <div className="form-group">
                  <label htmlFor="accountAddressName">{t("frontend.account.nickname")}</label>
                  <input
                    className="form-control"
                    type="text"
                    required={saveAddress}
                    id="accountAddressName"
                    value={accountAddressName}
                    onChange={(e) => {
                      e.preventDefault();
                      setAccountAddressName(e.target.value);
                    }}
                  />
                </div>
              )}
            </div>
          )}
          <div className="col-sm-6 d-flex justify-content-end align-items-start mt-2">
            <button
              className="btn btn-primary "
              disabled={disableSave}
              onClick={(e) => {
                e.preventDefault();
                onSave({
                  name,
                  countryCode,
                  stateCode,
                  postalCode,
                  locality,
                  city,
                  streetAddress,
                  street2Address,
                  phoneNumber,
                  emailAddress,
                  saveAddress,
                  accountAddressName,
                });
              }}
            >
              <span className="d-inline">{t("frontend.core.save")}</span>
            </button>
            {onCancel && (
              <button
                className="btn btn-primary mx-2"
                onClick={(e) => {
                  e.preventDefault();
                  onCancel();
                }}
              >
                <span className="d-inline">{t("frontend.account.address.cancel")}</span>
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

const FulfilmentAddressSelector = ({ 
  onSelect, 
  onSave, 
  fulfillment, 
  addressTitle = "frontend.account.addresses", 
  setRestrictionDisable,
  fflModal, 
  setChangeFFLAddress,
  fflAddressFlag,
  FULLFILLMENTFLAGDATA,
  orderID,
  isCalling,
  isFetching
  }) => {
  const {
    CommonModule: { SwRadioSelect },
  } = useElementContext();
  const accountAddresses = useSelector(getAllAccountAddresses);
  const user = useSelector((state)=> state.userReducer)
  const dispatch = useDispatch()
  const { accountAddress, shippingAddress } = fulfillment;
  const [changeAddress, setChangeAddress] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const { t } = useTranslation();
  const hasShippingAddress = shippingAddress?.addressID?.length > 0;
  const showAddressCard = !changeAddress && shippingAddress?.addressID?.length > 0;
  const showAddressPicker = (changeAddress || shippingAddress?.addressID?.length === 0) && !showAddress;
  const restrictions = useRestrictions();
  const navigate = useNavigate()
  const [restrictionModal, setRestrictionModal] = useState(false);
  const [foidRestrictionModal, setFoidRestrictionModal] = useState(false);

  
  useEffect(()=>{
    if(shippingAddress?.stateCode || shippingAddress?.postalCode){
      
      if(restrictions?.length && !changeAddress){
        for(const restriction in restrictions){
          if(restrictions[restriction]?.foidFlag){
            if(!restrictions[restriction]?.toBeFFLShipped){
            if(shippingAddress.stateCode === "IL"){
              if(!user?.foidNumber){
                setRestrictionDisable(true)
                setFoidRestrictionModal(true)
                return;
              } else if((new Date(user?.foidExpiryDate)).valueOf() < (new Date()).valueOf()){
                setRestrictionDisable(true)
                setFoidRestrictionModal(true)
                return;
              } else{
                setRestrictionDisable(false)
              }
            }
            }
          }
          
          if(restrictions[restriction]?.restrictedStates?.length){
            // for(const orderItem in orderItems){
            //   if(orderItems[orderItem]?.orderItemID === restrictions[restriction]?.orderItemID){
                
            //   }
            // }
            for(const fulfillmentItem in fulfillment?.orderFulfillmentItems){
              if(fulfillment?.orderFulfillmentItems[fulfillmentItem]?.orderItemID === restrictions[restriction]?.orderItemID){
                if(restrictions[restriction]?.restrictedStates.includes(shippingAddress?.stateCode)){
                  setRestrictionDisable(true)
                  setRestrictionModal(true)
                  return;
                }else{
                    setRestrictionDisable(false)
                  }
              }
            }
          }
          if(restrictions[restriction]?.restrictedZipCodes?.length){
            for(const fulfillmentItem in fulfillment?.orderFulfillmentItems){
              if(fulfillment?.orderFulfillmentItems[fulfillmentItem]?.orderItemID === restrictions[restriction]?.orderItemID){
                if(restrictions[restriction]?.restrictedZipCodes.includes(shippingAddress?.postalCode)){
                  setRestrictionDisable(true)
                  setRestrictionModal(true)
                  return;
                }else{
                    setRestrictionDisable(false)
                }
              }
              
            }
          }
          
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[shippingAddress, user, restrictions, changeAddress])
  
  return (
    <>
    <div className="fulfilmentAddressSelector">
    <hr/>
      <h5 className="h5 pt-1 pb-2 mb-3 border-bottom">{t(addressTitle)}</h5>

      {showAddressCard && (
        <div className="row ">
          <div className="bg-lightgray rounded mb-2 col-md-4 p-3" key={shippingAddress?.addressID}>
            <div>
              <b>{shippingAddress.name}</b>
              <br />
              {shippingAddress.streetAddress} <br />
              {shippingAddress.street2Address} <br />
              {`${shippingAddress.city}, ${shippingAddress.stateCode} ${shippingAddress.postalCode}`} <br />
            </div>
            <hr />
           
            <button
              className="btn btn-link p-0 text-danger"
              type="button"
              disabled={isCalling || isFetching}
              onClick={(event) => {
                event.preventDefault();
                setChangeAddress(true);
              }}
            >
              <i className="bi bi-times-circle"></i>
              <span className="small"> {t(`frontend.core.changeSelection`)}</span>
            </button>
          </div>
        </div>
      )}
      {showAddressPicker && (
        <div className="row mb-2">
          <div className="col-sm-12">
            {!showAddress && (
              <SwRadioSelect
                options={accountAddresses.map(({ accountAddressName, accountAddressID, address }) => {
                  const addressString = ` ${address.streetAddress}, ${address.city}, ${address.stateCode} ${address.postalCode}`;
                  const name = accountAddressName ? `${accountAddressName} - ${addressString} ` : addressString;
                  return { name, value: JSON.stringify({accountAddressID, address}) };
                })}
                onChange={(value) => {
                  !hasShippingAddress && setShowAddress(false);
                  let values = JSON.parse(value)
                  onSelect(values?.accountAddressID)?.then(async () => {
                    
                    try{
                      const servicePath = "api/scope/splitFFLFulfillment";
                      const serviceDomain = process.env.REACT_APP_API_URL;
                      const response = await axios (serviceDomain + servicePath, {
                        data: { 
                          "returnJSONObjects" : "cart", 
                          "fflDealerID" : FULLFILLMENTFLAGDATA?.fflDealerID ? FULLFILLMENTFLAGDATA.fflDealerID : "",
                          "orderID" : orderID,
                          "stateCode": values?.address?.stateCode ? values.address.stateCode : ""
                        },
                        withCredentials: false,
                        method: 'POST',
                      }
                      );
                      // if(!response){
                      //   setError({ isError: true, message: "An error occured" });
                      // }
                      // else{
                      //   setError({ isError: false, message: "" });
                      // }
                      if(!response?.data?.failureActions?.length)
                      {
                        dispatch(receiveCart(response?.data.cart));
                        dispatch(getCart());
                      }
                      
                    }
                    catch(err){
                    console.warn(err)
                      // setData({});
                      // setError({ isError: true, message: "An error occured" });
                    }
                    setChangeAddress(false);
                  });
                }}
                customLabel={t("frontend.checkout.receive_option")}
                selectedValue={accountAddress?.accountAddressID}
                displayNew={true}
              />
            )}
            {!showAddress && (
              <button className="btn btn-primary mt-2" onClick={() => setShowAddress(true)}>
                {t("frontend.account.address.add")}
              </button>
            )}
          </div>
        </div>
      )}
      {showAddress && (
        <>
          <BillingAddress
            setShowAddress={showAddress}
            onSave={(values) => {
              onSave(values).then(() => {
                setChangeAddress(false);
                setShowAddress(false);
              });
            }}
            onCancel={() => setShowAddress(false)}
            foidNumber={user?.foidNumber}
          />
        </>
      )}
    </div>
    <Modal show={restrictionModal} setShow={setRestrictionModal} title="Order Contains Restricted Products" backgroundstatic={true}>
      <h5>Due to federal or local laws some of these products are prohibited from shipping to your location.</h5>
      <h5>Please feel free to reach out to AmmoPlanet at info@ammoplanet.com</h5>
      <div className="d-flex flex-wrap mb-2 mb-sm-0 justify-content-around mt-3">
      <button className="btn btn-primary" onClick={()=>navigate("/shopping-cart")}>
        Return to Cart
      </button>
      <button className="btn btn-primary" onClick={(event)=>{
        event.preventDefault()
        setRestrictionModal(false)
        setChangeAddress(true)
      }}>
        Change Address
      </button>
      </div>
    </Modal>
    {!user?.calculatedGuestAccountFlag && 
    <Modal show={foidRestrictionModal} setShow={setFoidRestrictionModal} title="Foid Restrictions Applied for products" backgroundstatic={true}>
      <h5>Please update your FOID details in the profile page and get it verified.</h5>
      <div className="d-flex flex-wrap mb-2 mb-sm-0 justify-content-around mt-3">
      <button className="btn btn-primary" onClick={()=>navigate("/my-account/profile")}>
        Update FOID
      </button>
      </div>
    </Modal>
    }
    {user?.calculatedGuestAccountFlag && 
    <Modal show={foidRestrictionModal} setShow={setFoidRestrictionModal} title="Foid Restrictions Applied for products" backgroundstatic={true}>
      <h5>Some products in your cart needs FOID details for the selected address. This cannot be done with a guest account.</h5>
      <h5>Please signup with an account or change the address</h5>
      <div className="d-flex flex-wrap mb-2 mb-sm-0 justify-content-around mt-3">
      <button className="btn btn-primary" onClick={async ()=>{
        await dispatch(logout(t("frontend.account.logout_success"), t("frontend.account.logout_failure"))).then(()=>{
          navigate("/register")
        })
        
      }}>
        Create an Account
      </button>
      <button className="btn btn-primary" onClick={(event)=>{
        event.preventDefault()
        setFoidRestrictionModal(false)
        setChangeAddress(true)
      }}>
        Change Address
      </button>
      </div>
    </Modal>
    }
    </>
  );
};

export { FulfilmentAddressSelector, BillingAddress };
