import { useTranslation } from "react-i18next";

import {ShippingInsurance} from './ShippingInsurance';
import CartModel from "@ultracommerce/react-storefront/global/src/pages/Cart/Cart.componentModel";
import { useElementContext } from "@ultracommerce/react-storefront/global";

const View = ({
  componentData: { isEmptyCart, components, isRemovingItem, showClearCart },
  onClearCartData,
  onRemoveItem,
  onUpdateQty,
  onApplyCode,
}: {
  componentData: CartModel;
  onClearCartData: () => void;
  onRemoveItem: (id: string) => void;
  onUpdateQty: (id: string, qty: number) => void;
  onApplyCode: (promoCode: string, setPromoCode: (code: string) => void) => void;
}) => {
  const {
    CartModule,
    PageModule: { DynamicPage },
  } = useElementContext();
  const { t } = useTranslation();

  return (
    <DynamicPage ignoreLayout={true}>
      <div className="container my-5">
        {isEmptyCart && (
          <div className="text-center p-3">
            <h3>{t("frontend.cart.empty_cart")}</h3>
          </div>
        )}

        {isRemovingItem && <div className="alert alert-success">{t("frontend.cart.removeCartItem")}</div>}

        <div className="row">
          <div className="col-lg-7 col-md-12">
            {components.cartOrderItemList && (
              <CartModule.CoreControl.CartItemList
                {...components.cartOrderItemList}
                onUpdateQty={onUpdateQty}
                onRemoveItem={onRemoveItem} 
                onClearCartData={onClearCartData}
              />
            )}
            {components.quoteOrderItemsList && (
              <CartModule.CoreControl.CartItemList
                {...components.quoteOrderItemsList}
                onUpdateQty={onUpdateQty}
                onRemoveItem={onRemoveItem} 
                onClearCartData={onClearCartData}
              />
            )}
            
            {showClearCart && (
            <>
              <ShippingInsurance />
              <div className="white-background">
                <button className="btn btn-link link-btn" onClick={onClearCartData}>
                  {t("frontend.cart.clearCart")}
                </button>
              </div>
              </>
            )}
           
          </div>
          <div className="col-lg-4 offset-lg-1 col-md-12 pt-4">
            {components.cartCheckoutOptions && (
              <CartModule.CoreControl.CartCheckoutOptions {...components.cartCheckoutOptions} />
            )}
            {components.cartCheckOut && (
              <CartModule.CoreControl.CartCheckOut {...components.cartCheckOut} onApplyCode={onApplyCode} />
            )}
          </div>
        </div>
      </div>
    </DynamicPage>
  );
};

export default View;
