import LogoImage from "../../assets/Images/logo_img.svg"


const modalSizes = {
  default: "",
  small: "modal-sm",
  medium: "modal-md",
  large: "modal-lg",
  xLarge: "modal-xl",
};


const AgeConsentModal = ({
  show = true,
  setShow,
  title = "Modal Title",
  children,
  size = "default",
  footer = false,
  backgroundstatic = false,
}) => {
  return (
    <div
      className="modal"
      tabIndex="-1"
      aria-modal="true"
      role="dialog"
      style={{ display: show ? "inline" : "none" }}
      onClick={() => setShow(backgroundstatic)}
    >
      <div className={"modal-dialog modal-dialog-centered " + modalSizes[size]} onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
        
              <img
                src={LogoImage}
                className="img-fluid navbar-brandLogo"
                alt="frontend.logo"
                style={{ maxHeight: "60px", minWidth: "150px", marginTop:"20px" }}
              />
    
          <div className="modal-body" style={{textAlign: "center"}}>{children}</div>
          {footer && (
            <div>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setShow(false)}
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { modalSizes, AgeConsentModal };
