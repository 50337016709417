import { useState, useEffect } from "react";
import {useDispatch} from "react-redux";
import { axios } from "@ultracommerce/react-storefront/global";
import { receiveCart } from "@ultracommerce/react-storefront/global";
import { getCart } from "@ultracommerce/react-storefront/global";

const serviceDomain = process.env.REACT_APP_API_URL;

export const useAddFFLAddress = (orderFulfillmentID, selectedFFLDealersID, orderID, fflAddressFlag=false, ok, normal=true, fflOrder=true, isGetFFLDetailsFetching, shippingID, stateCode, isCalling, setIsCalling, hasSelected ) => {
  let [isFetching, setFetching] = useState(false);
  let [data, setData] = useState({});
  const dispatch = useDispatch();
  let [error, setError] = useState({ isError: false, message: "" });
  useEffect(() => {
    let source = axios.CancelToken.source();
    
    async function addFFLShippingAddress () {
        try{
          setFetching(true);
          const servicePath = "api/scope/splitFFLFulfillment";
          const response = await axios (serviceDomain + servicePath, {
            data: { 
              "returnJSONObjects" : "cart", 
              "fflDealerID" : !normal? "":selectedFFLDealersID,
              "orderID" : orderID,
              "stateCode": stateCode
            },
            withCredentials: false,
            method: 'POST',
          }
          );
          setData(response?.data);
          if(!response){
            setError({ isError: true, message: "An error occured" });
          }
          else{
            setError({ isError: false, message: "" });
          }
          if(!response?.data?.failureActions?.length)
          {
            dispatch(receiveCart(response?.data.cart));
            dispatch(getCart());
          }
          
        }
        catch(err){
          setData({});
          setError({ isError: true, message: "An error occured" });
        }finally{
                setFetching(false)
                if(isCalling){
                setIsCalling(false)
      }
            }
    }
    if((shippingID && selectedFFLDealersID && !normal && !isGetFFLDetailsFetching && !(fflOrder === true && fflAddressFlag === false) && ((ok && fflAddressFlag === true) || fflOrder === false )) || (selectedFFLDealersID && normal)){
      addFFLShippingAddress();
      
    }
    return () => {
      source.cancel();
    };
    // eslint-disable-next-line 
  }, [orderFulfillmentID, selectedFFLDealersID, ok, orderID, dispatch, hasSelected]);
  
  return {data, error, isFetching};
};