import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ProductTypeListModel } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/ProductTypeList/ProductTypeList.componentModel";
import { getProductTypeRoute } from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";

const ProductTypeListView = ({
  componentData: { childProductTypes, showOnlyDirectChild = false, isFetching = true },
}: {
  componentData: ProductTypeListModel;
}) => {
  const productTypeRoute = useSelector(getProductTypeRoute);
  const {
    CommonModule: { SWImage, Spinner },
  } = useElementContext();

  if (isFetching)
    return (
      <div className="container bg-light box-shadow-lg rounded-lg p-5">
        <Spinner />
      </div>
    );

  if (!childProductTypes.length) return null;
  
  return (
    <div className="container pb-4 pb-sm-5">
      <div className="row">
        {childProductTypes?.map(({ productTypeID, productTypeName, imageFile, urlTitle, childProductTypes }: any) => {
          let customImagePath = "";
          // let imageFileName = "";
          if (imageFile !== "") {
            // imageFileName = imageFile.split("/").reverse()?.at(0);
            customImagePath = imageFile.split("/").slice(0, -1).join("/") + "/";
          }

          return (
            <div className="childProductType col-lg-5-cols col-md-4 col-6 mb-3" key={productTypeID}>
              <div className="card border-0">
                <Link
                  to={`/${productTypeRoute}/${urlTitle}`}
                  onClick={() =>
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    })
                  }
                  className="d-block overflow-hidden rounded-lg"
                >
                  <SWImage
                    className="productImage d-block w-100"
                    customPath={customImagePath}
                    src={imageFile}
                    alt={productTypeName}
                    type="productType"
                  />
                </Link>
                <div className="card-body py-0 mb-2">
                  <h4 className="productType-title text-center">
                    <Link
                      to={`/${productTypeRoute}/${urlTitle}`}
                      className="link-button"
                      onClick={() =>
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        })
                      }
                    >
                      {productTypeName}
                    </Link>
                  </h4>
                  {!showOnlyDirectChild && (
                    <ul className="list-unstyled font-size-sm mb-0">
                      {childProductTypes
                        ?.sort((a: any, b: any) => (a.productTypeName > b.productTypeName ? 1 : -1))
                        ?.map(({ productTypeID, productTypeName, urlTitle }: any) => {
                          return (
                            <li className="d-flex align-items-center justify-content-between" key={productTypeID}>
                              <Link
                                to={`/${productTypeRoute}/${urlTitle}`}
                                className="link-button nav-link-style d-flex align-items-center justify-content-between text-left"
                              >
                                <i className="bi bi-chevron-circle-right pr-2"></i>
                                {productTypeName}
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export { ProductTypeListView };
