import { useTranslation } from "react-i18next";
import React from "react";
import { useCheckoutUtilities } from "@ultracommerce/react-storefront/global";
import { useState, useEffect } from "react";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { useSelector } from "react-redux";

const FulfillmentList = ({
  orderFulfillments,
  orderItems,
  canSwitchFulfillmentMethod = false,
  eligibleFulfillmentMethods,
  pickupLocations,
  selectedFulfillmentMethod,
  onChangeOrderFullfillment,
  onShipmentSelect,
  onShipmentSave,
  onSelectShippingMethod,
  onChangeDate,
  onChangeLocation,
  setRestrictionDisable,
  orderID,
  setIsCalling,
  isCalling,
  isFetching,
  handleCallback
}) => {
  const {
    CommonModule: {
      ShippingFulfillment,
      PickupFulfillment,
      FulfillmentPicker,
      AutoFulfillment,
      SimpleImage,
    },
  } = useElementContext();
  const { SHIPPING_CODE, PICKUP_CODE, AUTO_CODE } = useCheckoutUtilities();
  const [changeOrderFulfillment, setChangeOrderFulfillment] = useState(false);
  const { t } = useTranslation();
  let fflListed = 0;
  const [fflListedData, setFflListedData] = useState([]);
  const [shippingFulfillmentIDs, setShippingFulfillmentIDs] = useState([]);
  let shippingListed = 0;
  const [shippingListedData, setShippingListedData] = useState([]);
  const custDetails = useSelector((state) => state.userReducer);
  function flatten(arr) {
    return arr.reduce(function (flat, toFlatten) {
      return flat.concat(
        Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
      );
    }, []);
  }
  useEffect(() => {
    if (orderFulfillments) {
      // let orderFulfillmentsUpdated = [...orderFulfillments];
      setFflListedData(
        flatten(
          orderFulfillments
            ?.filter((item) => item.fflFulfillment)
            .map((item) => item.orderFulfillmentItems)
        )
      );
      setShippingListedData(
        flatten(
          orderFulfillments
            ?.filter((item) => !item.fflFulfillment)
            .map((item) => item.orderFulfillmentItems)
        )
      );
      setShippingFulfillmentIDs(
        orderFulfillments
          ?.filter((item) => !item.fflFulfillment)
          .map((item) => item.orderFulfillmentID)
      );
      // setOrderFulfillments(orderFulfillmentsUpdated);
    }
    // eslint-disable-next-line
  }, [orderFulfillments]);
  if (orderFulfillments.length === 0) {
    return null;
  }
  
  return (
    <>
      <div className="row">
        <h2>{t("frontend.checkout.fulfillments.shipping")}</h2>
        <hr />
        <div className="d-none">
          <FulfillmentPicker
            onSelect={() => {
              setChangeOrderFulfillment(false);
             
            }}
            orderItems={orderItems}
            eligibleFulfillmentMethods={eligibleFulfillmentMethods}
            onChangeOrderFullfillment={(
              fulfillmentMethodID,
              orderItemIDList
            ) => {
              onChangeOrderFullfillment(fulfillmentMethodID, orderItemIDList);
            }}
            selectedFulfillmentMethod={selectedFulfillmentMethod}
          />
        </div>
        <div>
          <h5>{t("frontend.confirmdetails")}</h5>
          <div className="row">
            {custDetails?.firstName && (
              <div className="me-2 d-flex col-5 flex-wrap">
                <b>{t("frontend.contact.firstName")}: &nbsp;</b>
                <p>{custDetails.firstName}</p>
              </div>
            )}
            {custDetails?.lastName && (
              <div className="d-flex col-5 flex-wrap">
                <b>{t("frontend.contact.lastName")}: &nbsp;</b>
                <p>{custDetails.lastName}</p>
              </div>
            )}
          </div>
          <div className="row">
            {custDetails?.company && (
              <div className="me-2 d-flex col-5 flex-wrap">
                <b>{t("frontend.account.company")}: &nbsp;</b>
                <p>{custDetails.company}</p>
              </div>
            )}
            {custDetails?.primaryEmailAddress?.emailAddress && (
              <div
                className={`d-flex ${
                  custDetails?.company ? "col-5" : "col-12"
                } flex-wrap`}
              >
                <b>{t("frontend.contact.emailAddress")}: &nbsp;</b>
                <p>{custDetails.primaryEmailAddress.emailAddress}</p>
              </div>
            )}
          </div>
          {custDetails?.primaryPhoneNumber?.phoneNumber && (
            <div className="row">
              <div className="d-flex flex-wrap">
                <b>{t("frontend.contact.phoneNumber")}: &nbsp;</b>
                <p>{custDetails.primaryPhoneNumber.phoneNumber}</p>
              </div>
            </div>
          )}
        </div>
        {!changeOrderFulfillment &&
          orderFulfillments?.map((fulfillment, primeindex) => {
            if (fulfillment.fflFulfillment) {
              fflListed = fflListed + 1;
            }
            if (!fulfillment.fflFulfillment) {
              shippingListed = shippingListed + 1;
            }
            if (
              (fulfillment.fflFulfillment && fflListed === 1) ||
              (!fulfillment.fflFulfillment && shippingListed === 1)
            ) {
              return (
                <div
                  className="bg-lightgray rounded mb-5 col-12 p-3"
                  key={primeindex}
                >
                  <div>
                    {fulfillment.fflFulfillment &&
                      fflListedData?.map((item, index) => {
                        return (
                          <div
                            className="d-flex gap-2 flex-wrap mb-3"
                            key={index}
                          >
                            {orderItems.map((orderItem, itemIndex) => {
                              if (orderItem.orderItemID === item.orderItemID) {
                                return (
                                  <React.Fragment key="itemIndex">
                                    <div className="col-3 image">
                                      <SimpleImage
                                        className="img-fluid  m-auto image_container productImage"
                                        src={orderItem.sku?.imagePath}
                                        alt={orderItem.sku?.product.productName}
                                        type="product"
                                      />
                                    </div>
                                    {item.sku?.product?.productName && (
                                      <div className="col-8 m-2 mt-3">
                                        <h4 className="fw-bold pt-0 mt-0">
                                          {item.sku.product.productName}
                                        </h4>
                                        {orderItem.sku?.skuCode && (
                                          <p
                                            className="mb-0"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {orderItem.sku.skuCode}
                                          </p>
                                        )}
                                        <p>Qty: {orderItem.quantity}</p>
                                      </div>
                                    )}
                                  </React.Fragment>
                                );
                              } else {
                                return (
                                  <React.Fragment
                                    key={itemIndex}
                                  ></React.Fragment>
                                );
                              }
                            })}
                          </div>
                        );
                      })}
                    {!fulfillment.fflFulfillment &&
                      shippingListedData?.map((item, index) => {
                        return (
                          <div
                            className="d-flex gap-2 flex-wrap mb-3"
                            key={index}
                          >
                            {orderItems.map((orderItem, itemIndex) => {
                              if (orderItem.orderItemID === item.orderItemID) {
                                return (
                                  <React.Fragment key={itemIndex}>
                                    <div className="col-3 image">
                                      <SimpleImage
                                        className="img-fluid  m-auto image_container productImage"
                                        src={orderItem.sku?.imagePath}
                                        alt={orderItem.sku?.product.productName}
                                        type="product"
                                      />
                                    </div>
                                    {item.sku?.product?.productName && (
                                      <div className="col-8 m-2 mt-3">
                                        <h4 className="fw-bold pt-0 mt-0">
                                          {item.sku.product.productName}
                                        </h4>
                                        {orderItem.sku?.skuCode && (
                                          <p
                                            className="mb-0"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {orderItem.sku.skuCode}
                                          </p>
                                        )}
                                        <p>Qty: {orderItem.quantity}</p>
                                      </div>
                                    )}
                                  </React.Fragment>
                                );
                              } else {
                                return (
                                  <React.Fragment
                                    key={itemIndex}
                                  ></React.Fragment>
                                );
                              }
                            })}
                          </div>
                        );
                      })}
                  </div>

                  {fulfillment.fulfillmentMethod.fulfillmentMethodType ===
                    SHIPPING_CODE && (
                    <ShippingFulfillment
                      fulfillment={fulfillment}
                      orderItems={orderItems}
                      allowSwith={canSwitchFulfillmentMethod}
                      onShipmentSelect={onShipmentSelect}
                      onShipmentSave={onShipmentSave}
                      onSelectShippingMethod={onSelectShippingMethod}
                      setRestrictionDisable={setRestrictionDisable}
                      orderID={orderID}
                      shippingFulfillmentIDs={shippingFulfillmentIDs}
                      orderFulfillments={orderFulfillments}
                      setIsCalling={setIsCalling}
                      isCalling={isCalling}
                      fetching={isFetching}
                      handleCallback={handleCallback}
                    />
                  )}
                  {fulfillment.fulfillmentMethod.fulfillmentMethodType ===
                    PICKUP_CODE && (
                    <PickupFulfillment
                      fulfillment={fulfillment}
                      orderItems={orderItems}
                      allowSwith={canSwitchFulfillmentMethod}
                      onChangeDate={onChangeDate}
                      onChangeLocation={onChangeLocation}
                      pickupLocations={pickupLocations}
                    />
                  )}
                  {fulfillment.fulfillmentMethod.fulfillmentMethodType ===
                    AUTO_CODE && (
                    <AutoFulfillment
                      fulfillment={fulfillment}
                      orderItems={orderItems}
                      allowSwith={canSwitchFulfillmentMethod}
                    />
                  )}
                  <hr />
                </div>
              );
            } else {
              return <React.Fragment key={primeindex}></React.Fragment>;
            }
          })}
      </div>
      {changeOrderFulfillment && (
        <div className="row ">
          <FulfillmentPicker
            onSelect={() => {
              setChangeOrderFulfillment(false);
            }}
            orderItems={orderItems}
            eligibleFulfillmentMethods={eligibleFulfillmentMethods}
            onChangeOrderFullfillment={(
              fulfillmentMethodID,
              orderItemIDList
            ) => {
              onChangeOrderFullfillment(fulfillmentMethodID, orderItemIDList);
            }}
            selectedFulfillmentMethod={selectedFulfillmentMethod}
          />
          <div className="col-sm-6">
            <button
              className="btn btn-secondary mx-2"
              type="button"
              onClick={() => setChangeOrderFulfillment(false)}
            >
              {t("frontend.core.cancel")}
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export { FulfillmentList };
