import Slider from "react-slick";
import { useGetProductsByEntityModified } from "@ultracommerce/react-storefront/global";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { sorting } from "@ultracommerce/react-storefront/global";
import { useState } from 'react'
import { useSelector } from "react-redux";
import {Modal} from '../../components/Modal/Modal'
import { useNavigate, Link } from 'react-router-dom'
import { getProductRoute } from "@ultracommerce/react-storefront/global";

const ProductSlider = ({ cardConfiguration, children, settings, title, slidesToShow = 4, products = [] }) => {
  const { ProductCardModule,ProductModule, CommonModule: { SimpleImage }, } = useElementContext();
  const [updateQtyModal, setUpdateQtyModal] = useState(false);
  const [itemData, setItemData] = useState({})
  const [updateQtyError, setUpdateQtyError] = useState("")
  const navigate = useNavigate();
  const productRouting = useSelector(getProductRoute);

  settings = settings
    ? settings
    : {
        dots: false,
        infinite: products.length >= slidesToShow,
        // infinite: true,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };

  return (
    <>
    <section className="py-4 product-slider-sec">
      <div className="container">
        <header className="section-title">
          <h2>{title}</h2>
        </header>
        <div className="card border-0 bg-transparent">
          {children}
          <Slider {...settings}>
            {products?.map((slide, index) => {
              return (
                <div className="repeater" key={index}>
                  {/*Fixed the slider design issue */}
                  <div className="card-body h-100">
                    <ProductCardModule.CoreControl.SkuCard {...slide} setItemData={setItemData} setUpdateQtyError={setUpdateQtyError} setUpdateQtyModal={setUpdateQtyModal} cardConfiguration={cardConfiguration} />
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
    <Modal show={updateQtyModal} setShow={setUpdateQtyModal} size="large" title="These item(s) may not have been added" backgroundstatic={true}>
      { 
        updateQtyError && <h3 className="bg-light text-danger d-flex text-center align-items-center p-3">{updateQtyError}</h3>
      }
      
      
        {itemData &&
          <div className="row py-3 cart-item-box">
            { itemData.sku && <div className="row detail">
              <div className="col-sm-2 col-3 image">
                {itemData?.sku?.imageFile && (
                  <SimpleImage
                    className="img-fluid  m-auto image_container productImage"
                    src={itemData?.sku?.imageFile}
                    alt={itemData?.sku?.product_productName}
                    type="product"
                  />
                )}
              </div>
              <div className="col-sm-4 col-9">
                <div className="title">
                  <h4>
                    <Link
                      to={{
                        pathname: `/${productRouting}/${itemData?.sku?.product_urlTitle}`,
                        // @ts-ignore
                        state: { ...itemData?.sku},
                      }}
                      className="text-decoration-none text-dark"
                    >
                      {itemData?.sku?.product_productName}
                    </Link>
                  </h4>
                </div>
                <div className="font-size-sm item-sku">
                  <span className="mr-2">SKU : {itemData?.sku?.skuCode}</span>
                </div>
                
               
              </div>
              <div className="col-sm-12 col-md-6 d-flex bottom-detail-box d-none d-md-block">
                <div className="row">
                  <div className="item-price col-sm-3">
                    <ProductModule.CoreComponents.ProductPrice
                      type="cart"
                      salePrice={itemData?.sku?.salePrice}
                      listPrice={itemData?.sku?.listPrice}
                    />
                  </div>
                </div>
              </div>
            </div>}
            
          </div>
        }
        <div className="d-flex justify-content-around mt-3"> 
          <button className="btn btn-primary mw-25 w-25" onClick={()=>setUpdateQtyModal(false)}>
            Continue Shopping
          </button>
          <button className="btn btn-primary mw-25 w-25" onClick={()=>navigate("/shopping-cart")}>
            Go to Cart 
          </button>
        </div>
    </Modal>
    </>
  );
};

const ProductSliderWithList = ({ children, params = {}, settings, title, slidesToShow, productList = [] }) => {
  let [request, setRequest] = useGetProductsByEntityModified();

  useEffect(() => {
    let didCancel = false;
    if (!didCancel && !request.isFetching && !request.isLoaded && productList.length) {
      setRequest({
        ...request,
        params: {
          ...params,
          includeImages: true,
          "f:ProductID:in": productList.join(","),
        },
        entity: "product",
        makeRequest: true,
        isFetching: true,
        isLoaded: false,
      });
    }
    return () => {
      didCancel = true;
    };
  }, [request, setRequest, params, productList]);
  if (!request.data.length || productList.length === 0) {
    return null;
  }
  const sortedProducts = sorting(request.data, productList, "productCode");

  return (
    <ProductSlider products={sortedProducts} settings={settings} title={title} slidesToShow={slidesToShow}>
      {children}
    </ProductSlider>
  );
};

const ProductSliderWithConfig = ({ children, params = {}, settings, title, slidesToShow }) => {
  let [request, setRequest] = useGetProductsByEntityModified();
  const { t } = useTranslation();
  useEffect(() => {
    let didCancel = false;
    if (!didCancel && !request.isFetching && !request.isLoaded) {
      setRequest({
        ...request,
        params: { includeImages: true, ...params },
        entity: "product",
        makeRequest: true,
        isFetching: true,
        isLoaded: false,
      });
    }
    return () => {
      didCancel = true;
    };
  }, [request, setRequest, params]);
  if (!request.data.length) return null;

  return (
    <ProductSlider
      products={request.data}
      settings={settings}
      title={title || t("frontend.home.popular_products")}
      slidesToShow={slidesToShow}
    >
      {children}
    </ProductSlider>
  );
};

export { ProductSlider, ProductSliderWithConfig, ProductSliderWithList };
