import { PropsWithChildren, useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ToastContainer } from "react-toastify";
import { getConfiguration, evictAllPages, getUser, getWishLists, reciveConfiguration } from "@ultracommerce/react-storefront/global";
import { ContentContextProvider } from "@ultracommerce/react-storefront/global";
import { useElementContext, useServiceContext } from "@ultracommerce/react-storefront/global";
// import { useScrollToTop } from "@ultracommerce/react-storefront/global";
import { LocalStorageService } from "@ultracommerce/react-storefront/global";

const ReCaptchaProvider = ({ children }: PropsWithChildren<{}>) => {
  const siteSetting = useSelector((state: any) => state.configuration.site.settings);
  if (!siteSetting.siteRecaptchaSiteKey) return <>{children}</>;

  return (
    <GoogleReCaptchaProvider reCaptchaKey={siteSetting.siteRecaptchaSiteKey} useRecaptchaNet>
      {children}
    </GoogleReCaptchaProvider>
  );
};

const AppSwitcher = () => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const {
    CommonModule: { Theme, App, AnalyticsManager },
  } = useElementContext();
  const { StorefrontContentService, LogService } = useServiceContext();

  const [safeToLoad, setSafeToLoad] = useState(!pathname.startsWith("/ssoLogin"));
  const [configurationLoaded, setConfigurationLoaded] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getState } = useStore();

  useEffect(() => {
    if (pathname.startsWith("/ssoLogin")) {
      const authCode = new URLSearchParams(search).get("token") as string;
      const redirect = new URLSearchParams(search).get("redirect") || "/my-account/overview";
      LocalStorageService.setItem("token", authCode);
      dispatch(evictAllPages());
      dispatch<any>(getUser()).then(() => {
        dispatch<any>(getWishLists());
        navigate(redirect);
        toast.success(t("frontend.account.auth.success"));
        setSafeToLoad(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (safeToLoad) {
      dispatch<any>(getConfiguration()).then(() => {
        const state: any = getState();
        const oldSiteConfig = state.configuration;
        const service = new StorefrontContentService();
        service.fetchNewConfig(oldSiteConfig).then((newConfig: any) => {
          dispatch(reciveConfiguration(newConfig));
          console.log("#### Config Loaded ####", window.UC);
          setConfigurationLoaded(true);
        });
      });
    }
  }, [StorefrontContentService, dispatch, getState, safeToLoad]);

  useEffect(() => {
    const logLogService = new LogService();
    logLogService.initialize();
  }, [LogService]);

  return (
    <>
      {safeToLoad && configurationLoaded && (
        <ContentContextProvider> 
          <Theme> 
            <ToastContainer position="top-right" autoClose={3000} />
            <ReCaptchaProvider>
              <App />
            </ReCaptchaProvider>
            
          </Theme>
        </ContentContextProvider>
      )}
      <AnalyticsManager cookieSettings={{
        message: "This website uses cookies or similar technologies, to enhance your browsing experience and provide personalized recommendations. By continuing to use our website, you agree to our Privacy Policy",
        showDeclineButton: true, 
        managePreferencesButtonText: "Decline",
        privacyPolicyLinkText: "Privacy Policy",
        policyLink: "/privacy-policy",
        showPreferencesOption: false,
        showStatisticsOption: false,
        showMarketingOption: false,
        savePreferencesButtonText: "Close",
        acceptButtonText: "Accept"
      }} />
    </>
  );
};

export { AppSwitcher };
