import { useDispatch, useSelector } from "react-redux";
import {
  getEligibleFulfillmentMethods,
  getPickupLocations,
  changeOrderFulfillment,
  addShippingAddressUsingAccountAddress,
  addNewAddressAndAttachAsShipping,
  addShippingAddress,
  addShippingMethod,
} from "@ultracommerce/react-storefront/global";
import { useCallback, useEffect, useState } from "react";
import {
  getAllOrderItems,
  getAllOrderFulfillments,
  isAllowedToSwitchFulfillmentMethod,
  getAllEligibleFulfillmentMethods,
  fulfillmentMethodSelector,
} from "@ultracommerce/react-storefront/global";
import { Navigate } from "react-router-dom";
import { usePickupLocation } from "@ultracommerce/react-storefront/global";
import { pickupLocationOptions } from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { CheckBox } from "../ConcentBox/CheckBox";

const ShippingSlide = ({ currentStep }) => {
  const {
    CommonModule: { FulfillmentList, SlideNavigation, Overlay },
  } = useElementContext();
  const [isFFLOrder, setFFLOrderType] = useState("");
  const [fflAddressFlag, setFFlRequired] = useState(false);
  const dispatch = useDispatch();
  const [restrictionDisable, setRestrictionDisable] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const { orderRequirementsList, orderID } = useSelector((state) => state.cart);
  const allOrderFulfillments = useSelector(getAllOrderFulfillments);
  const { isFetching } = useSelector((state) => state.cart);
  let orderFulfillments = useSelector(getAllOrderFulfillments);
  let orderItems = useSelector(getAllOrderItems);
  const canSwitchFulfillmentMethod = useSelector(
    isAllowedToSwitchFulfillmentMethod
  );
  let eligibleFulfillmentMethods = useSelector(
    getAllEligibleFulfillmentMethods
  );
  const handleCallback = useCallback(
    ({ fflAddressFlag, fflOrder }) => {
      setFFlRequired(fflAddressFlag);
      setFFLOrderType(fflOrder);
    },
    [setFFlRequired, setFFLOrderType]
  );
  let selectedFulfillmentMethod = useSelector(fulfillmentMethodSelector);
  const { onChangeDate, onChangeLocation } = usePickupLocation();
  useEffect(() => {
    dispatch(getEligibleFulfillmentMethods());
    dispatch(getPickupLocations());
  }, [dispatch]);
  const pickupLocations = useSelector(pickupLocationOptions);

  if (
    allOrderFulfillments?.length === 1 &&
    allOrderFulfillments?.at(0)?.fulfillmentMethod?.fulfillmentMethodType ===
      "auto"
  ) {
    return <Navigate to={`../${currentStep.next}`} />;
  }
  const nonFFLRequirementList =
    !orderRequirementsList.includes("fulfillment") &&
    !restrictionDisable &&
    checked &&
    !isFetching &&
    !isCalling;
  const fflRequirementList = nonFFLRequirementList && fflAddressFlag;
  return (
    <>
      <Overlay
        active={isFetching}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(0, 0, 0, 0)",
          }),
          spinner: (base) => ({
            ...base,
            width: "100px",
            "& svg circle": {
              stroke: "rgba(211, 211, 211)",
            },
          }),
        }}
        spinner
      >
        <FulfillmentList
          orderFulfillments={orderFulfillments}
          orderItems={orderItems}
          orderID={orderID}
          canSwitchFulfillmentMethod={canSwitchFulfillmentMethod}
          selectedFulfillmentMethod={selectedFulfillmentMethod}
          eligibleFulfillmentMethods={eligibleFulfillmentMethods}
          pickupLocations={pickupLocations}
          onChangeOrderFullfillment={(fulfillmentMethodID, orderItemIDList) => {
            dispatch(
              changeOrderFulfillment({ fulfillmentMethodID, orderItemIDList })
            );
          }}
          onShipmentSelect={(value, orderFulfillmentID) => {
            setIsCalling(true);
            return dispatch(
              addShippingAddressUsingAccountAddress({
                accountAddressID: value,
                fulfillmentID: orderFulfillmentID,
              })
            ).then((resp) => setIsCalling(false));
          }}
          onShipmentSave={(values, orderFulfillmentID) => {
            setIsCalling(true);
            if (values.saveAddress) {
              return dispatch(
                addNewAddressAndAttachAsShipping({ ...values })
              ).then((resp) => setIsCalling(false));
            } else {
              return dispatch(
                addShippingAddress({
                  ...values,
                  fulfillmentID: orderFulfillmentID,
                  returnJSONObjects: "cart",
                })
              ).then((resp) => setIsCalling(false));
            }
          }}
          onSelectShippingMethod={(value, orderFulfillmentID) => {
            dispatch(
              addShippingMethod({
                shippingMethodID: value,
                fulfillmentID: orderFulfillmentID,
              })
            );
          }}
          handleCallback={handleCallback}
          onChangeDate={onChangeDate}
          onChangeLocation={onChangeLocation}
          setRestrictionDisable={setRestrictionDisable}
          setIsCalling={setIsCalling}
          isCalling={isCalling}
          isFetching={isFetching}
        />
      </Overlay>
      <CheckBox
        isCalling={isCalling}
        isFetching={isFetching}
        checked={checked}
        setChecked={setChecked}
      />
      <SlideNavigation
        currentStep={currentStep}
        nextActive={isFFLOrder ? fflRequirementList : nonFFLRequirementList}
      />
    </>
  );
};

export { ShippingSlide };
