import { useContentContext } from "@ultracommerce/react-storefront/global";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAccountProfile } from "../../../hooks/components/Account/useAccountProfile";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import "./AccountProfile.css"
const AccountProfile = ({ crumbs, title, contentBody, contentTitle }) => {
  const {
    CommonModule: { AccountContent, AccountLayout },
  } = useElementContext();
  const { t } = useTranslation();
  const { formik } = useAccountProfile();
  const pageData = useContentContext();
  let generalConfig = {};
  pageData?.contentElements?.map((pageEl, idx) => {
    if (pageEl?.systemCode === "cetColumns") {
      generalConfig = JSON.parse(pageEl.genConfig);
    }
    return null;
  });

  return (
    <AccountLayout crumbs={crumbs} title={title}>
      <AccountContent />

      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="accountFirstName">{t("frontend.account.first_name")}</label>
              <input
                className="form-control"
                type="text"
                id="accountFirstName"
                value={formik.values.accountFirstName}
                onChange={formik.handleChange}
                disabled={generalConfig.disableProfileEdit ? true : false}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="accountLastName">{t("frontend.account.last_name")}</label>
              <input
                className="form-control"
                type="text"
                id="accountLastName"
                value={formik.values.accountLastName}
                onChange={formik.handleChange}
                disabled={generalConfig.disableProfileEdit ? true : false}
              />
            </div>
          </div>
          <div className="col-sm-6 mt-1">
            <div className="form-group">
              <label htmlFor="accountEmailAddress">{t("frontend.account.email")}</label>
              <input
                className="form-control"
                type="email"
                id="accountEmailAddress"
                value={formik.values.accountEmailAddress}
                onChange={formik.handleChange}
                disabled={generalConfig.disableProfileEdit ? true : false}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="accountPhoneNumber">Phone Number</label>
              <input
                className="form-control"
                type="text"
                id="accountPhoneNumber"
                value={formik.values.accountPhoneNumber}
                onChange={formik.handleChange}
                required=""
                disabled={generalConfig.disableProfileEdit ? true : false}
              />
            </div>
          </div>
          {/* <div className="col-sm-2">
            <div className="form-group">
              <label htmlFor="accountExt">Ext.</label>
              <input className="form-control" type="text" id="accountExt" value={formik.values.accountExt} onChange={formik.handleChange} required="" />
            </div>
          </div> */}
          <div className="col-sm-6 mt-1">
            <div className="form-group">
              <label htmlFor="accountFOID">FOID Number:</label>
              <input
                className="form-control"
                value={formik.values.accountFOID}
                type="text"
                onChange={formik.handleChange}
                id="accountFOID"
                disabled={generalConfig.disableProfileEdit ? true : false}
              />
            </div>
          </div>
          <div className="col-sm-6 mt-1">
            <div className="form-group">
              <label htmlFor="accountFOIDExpiry">FOID Expiry Date:</label>
              <input
                className="form-control"
                value={formik.values.accountFOIDExpiry}
                type="date"
                onChange={formik.handleChange}
                id="accountFOIDExpiry"
                disabled={generalConfig.disableProfileEdit ? true : false}
              />
            </div>
          </div>
          
          {!generalConfig.disableProfileEdit && (
            <>
              <div className="col-12 mt-2 d-flex justify-content-between align-items-center">
                <button type="submit" className="btn btn-primary">
                  {t("frontend.core.save")}
                </button>
                <Link className="btn btn-secondary mr-3 custom-btn-text" to="/my-account/updatePassword">
                  {t("frontend.account.password_update")}
                </Link>
              </div>
            </>
          )}
        </div>
      </form>
    </AccountLayout>
  );
};

export { AccountProfile };
