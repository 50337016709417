import { ProductCardActionsView } from "@ultracommerce/react-storefront/global/src/modules/ProductCard/Components/ProductCardActions/ProductCardActions.componentView";
import { useComponentModel } from "@ultracommerce/react-storefront/global/src/modules/ProductCard/Components/ProductCardActions/ProductCardActions.componentService";
import { addToCart } from "../../../../actions/cartActions"
import { useCallback } from 'react'
import { useDispatch } from "react-redux";


export function ProductCardActions(props: any) {
  const { componentData, componentService } = useComponentModel({ ...props });
  const dispatch = useDispatch()
  const {setItemData, setUpdateQtyModal, setUpdateQtyError} = props?.sku
  
  const addToCartCallback = useCallback(
    async (itemCount: number) => {
      
      const cartData: any = await dispatch(addToCart(props.sku, itemCount) as any);
      if(Object.values(cartData.success().cart?.errors).length){
        //@ts-ignore
        setUpdateQtyError(Object.values(cartData?.success().cart?.errors)[0][0])
        setUpdateQtyModal(true)
        setItemData(props)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, props.sku.skuID],
  );
  
  if (!componentData.buttonStack?.length) return null;

  const onAddToCart = () => {
    addToCartCallback(componentData.itemCount);
  };
  
  

  //CUSTOM BUSINESS LOGIC HERE
  return (
    <ProductCardActionsView
      componentData={componentData}
      setQuantity={componentService.setQuantity}
      onAddToCart={onAddToCart}
      setProductModifierModal={componentService.setProductModifierModal}
    />
  );
}
