import { useState, useEffect } from "react";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { useRestrictions } from "../../../hooks/components/Cart/useRestrictions";
import { FFLSearch } from "./Shipping/FFLSearch";
import { useFulfilmentFlag } from "../../../hooks/components/Checkout/useFulfilmentFlag";
import { useNavigate } from "react-router-dom";
import { Modal } from "../../Modal/Modal";
import { useAddFFLAddress } from "../../../hooks/components/Checkout/useAddFFLAddress";

const ShippingFulfillment = ({
  fulfillment,
  onShipmentSelect,
  onShipmentSave,
  onSelectShippingMethod,
  setRestrictionDisable,
  orderID,
  shippingFulfillmentIDs,
  orderFulfillments,
  setIsCalling,
  isCalling,
  fetching,
  handleCallback
}) => {
  const { orderFulfillmentID } = fulfillment;
  const { shippingAddress } = fulfillment;
  const {
    CommonModule: { FulfilmentAddressSelector, ShippingMethodRates },
  } = useElementContext();
  const { fflRequired } = useRestrictions("ifFFLRequiredPresent");
  const { FULLFILLMENTFLAGDATA, isFetching } = useFulfilmentFlag(
    orderID,
    shippingAddress?.stateCode,
    fulfillment?.fflFulfillment,
    orderFulfillments?.length === 1
  );
  const [changeFFLAddress, setChangeAddress] = useState(false);
  const [hasSelected, setHasSelected] = useState(false);
  const fflOrder = FULLFILLMENTFLAGDATA?.fflOrder;
  const fflAddressFlag = FULLFILLMENTFLAGDATA?.fflAddressFlag;
  const firstTimeFFLSearch =
    FULLFILLMENTFLAGDATA?.orderFulfillmentItems?.length === 1 &&
    FULLFILLMENTFLAGDATA?.shippingAddressID &&
    fflOrder;
  const fflModal =
    FULLFILLMENTFLAGDATA?.fflOrder &&
    FULLFILLMENTFLAGDATA?.shippingAddressID &&
    (!fulfillment.fflFulfillment ||
      (fulfillment.fflFulfillment && orderFulfillments.length === 1));
  const [fFLDealerModal, setFFLDealerModal] = useState(
    FULLFILLMENTFLAGDATA ? fflModal : false
  );
  const [chooseDealer, setChooseDealer] = useState(false);
  const [ok, setOk] = useState(false);
  useAddFFLAddress(
    orderFulfillmentID,
    shippingAddress?.stateCode,
    orderID,
    fflAddressFlag,
    ok,
    false,
    fflOrder,
    isFetching,
    FULLFILLMENTFLAGDATA?.shippingAddressID,
    shippingAddress?.stateCode,
    isCalling,
    setIsCalling,
    hasSelected
  );
  const navigate = useNavigate();
  const updateChangeAddress = (newState) => {
    setChangeAddress(newState);
  };
  const GoToHandler = (value) => {
    document.getElementById(value)?.focus();
  };

  useEffect(() => {
    if (FULLFILLMENTFLAGDATA) {
      setFFLDealerModal(fflModal);
    }
    handleCallback({fflAddressFlag,fflOrder});
    // eslint-disable-next-line
  }, [fflModal]);

  return (
    <div className="shippingFulfillment">
      <div>
        {/* <OrderFulfillmentItems fulfillment={fulfillment} /> */}
        {((!fulfillment?.fflFulfillment && !firstTimeFFLSearch) ||
          orderFulfillments?.length === 1) && (
          <FulfilmentAddressSelector
            fulfillment={fulfillment}
            onSelect={(value) => {
              if (orderFulfillments?.length === 1) {
                return onShipmentSelect(value, orderFulfillmentID);
              } else {
                return shippingFulfillmentIDs?.map((id) => {
                  return onShipmentSelect(value, id);
                })[0];
              }
            }}
            onSave={(values) => {
              if (orderFulfillments?.length === 1) {
                return onShipmentSave(values, orderFulfillmentID);
              } else {
                return shippingFulfillmentIDs?.map((id, index) => {
                  if (index === 0) {
                    return onShipmentSave(values, id);
                  } else {
                    var value = values;
                    value.saveAddress = false;
                    return onShipmentSave(value, id);
                  }
                })[0];
              }
            }}
            setRestrictionDisable={setRestrictionDisable}
            setChangeFFLAddress={updateChangeAddress}
            fflModal={
              FULLFILLMENTFLAGDATA?.fflOrder &&
              FULLFILLMENTFLAGDATA?.shippingAddressID
            }
            fflAddressFlag={FULLFILLMENTFLAGDATA?.fflAddressFlag}
            FULLFILLMENTFLAGDATA={FULLFILLMENTFLAGDATA}
            orderID={orderID}
            isCalling={isCalling}
            isFetching={fetching}
          />
        )}
        <div
          id={fulfillment?.fflFulfillment ? "ffl-ref" : "ffl-ref-shipping"}
          tabIndex="0"
        >
          {((fulfillment?.fflFulfillment && fflRequired) ||
            firstTimeFFLSearch ||
            chooseDealer) && (
            <FFLSearch
              fulfillment={fulfillment}
              orderID={orderID}
              changeFFLAddress={changeFFLAddress}
              setChangeFFLAddress={setChangeAddress}
              setRestrictionDisable={setRestrictionDisable}
              chooseDealer={chooseDealer}
              setChooseDealer={setChooseDealer}
              setIsCalling={setIsCalling}
              isCalling={isCalling}
              GoToHandler={GoToHandler}
              hasSelected={hasSelected}
              setHasSelected={setHasSelected}
            />
          )}
        </div>
      </div>
      <Modal
        show={fFLDealerModal}
        setShow={setFFLDealerModal}
        title={fflAddressFlag ? "" : "FFL Dealer address is required"}
        backgroundstatic={true}
      >
        <h5>
          {fflAddressFlag
            ? "Some of your items in the cart will be shipped to the selected FFL dealer."
            : "Due to state restrictions, some of your items in the cart should be shipped to FFL dealer."}
        </h5>
        <div className="d-flex flex-wrap mb-2 mb-sm-0 justify-content-around mt-3">
          <button
            className="btn btn-primary"
            onClick={() => {
              navigate("/checkout/shipping");
              setFFLDealerModal(false);
              if (!fflAddressFlag) {
                setChangeAddress(true);
                setChooseDealer(true);
                GoToHandler("ffl-ref-shipping");
              } else {
                setOk(true);
                GoToHandler("ffl-ref");
              }
            }}
          >
            {fflAddressFlag ? "OK" : "Choose FFL Dealer"}
          </button>
        </div>
      </Modal>
      <div className="d-none">
        <ShippingMethodRates
          fulfillment={fulfillment}
          onSelection={(values) =>
            onSelectShippingMethod(values, orderFulfillmentID)
          }
        />
      </div>
    </div>
  );
};
export { ShippingFulfillment };
