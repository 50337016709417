import { useEffect } from "react";
import { axios } from "@ultracommerce/react-storefront/global";
import { useSelector } from "react-redux";
const serviceDomain = process.env.REACT_APP_API_URL;

const useViewedProduct = (product) => {
  const user = useSelector((state) => state.userReducer);
  const emailAddress =
    user?.primaryEmailAddress?.emailAddress ||
    localStorage.getItem("newsletteremailjson") ||
    "";

  useEffect(() => {
    if (emailAddress) {
      let data = {
        event: "Viewed Product",
        email: emailAddress,
        properties: {
          productName: product.productName,
          productID: product.productID,
          SkuImageFile: product.defaultSku_imageFile,
          skuCode: product.defaultSku_skuCode,
          skuID: product.defaultSku_skuID,
          listPrice: product.listPrice,
          salePrice: product.salePrice,
        },
      };
      axios(serviceDomain + "api/scope/triggerEvent", {
        data,
        withCredentials: false,
        method: "POST",
      }).then((res) => console.warn("res", res));
    }
    // eslint-disable-next-line
  }, [product, emailAddress]);
};

export default useViewedProduct;
