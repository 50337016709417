import { OrderItem } from "@ultracommerce/react-storefront/global/src/interface/Cart";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { useRestrictions } from "../../../hooks/components/Cart/useRestrictions";
import { toast } from "react-toastify";
import { useEffect } from "react";
import {useSelector} from 'react-redux'

export interface CartItemListProps {
  title: string;
  cartItems: (OrderItem & { errorMsg?: string })[];
  orderItems: OrderItem[];
  isRemovingItem: boolean;
  onRemoveItem: (id: string) => void;
  onUpdateQty: (orderItemID: string, quantity: number) => void;
  onClearCartData: any;
}

export const CartItemList = ({
  title,
  cartItems,
  isRemovingItem,
  orderItems,
  onRemoveItem,
  onUpdateQty,
  onClearCartData
}: CartItemListProps) => {

  const { CartModule } = useElementContext();
  const orderRestrictions = useRestrictions();
  let orderItems2Map: Record<string, any> ;
  let mergedOrderItems;
  let cart = useSelector((state: any)=>state.cart)
  
  if(orderRestrictions && cartItems && orderRestrictions!== "error"){
    orderItems2Map = cartItems?.reduce((map: any, item: any ) => {
    map[item.orderItemID] = item;
    return map;
    }, {});
  // Merge the arrays based on orderItemID
    mergedOrderItems = orderRestrictions?.map(( item1: any ) => ({
    ...item1,
    ...(orderItems2Map[item1.orderItemID] || {}), // Merge with corresponding item from the second array
    }));
  }
  
  useEffect(() => {
    // Check for the error after the component has been rendered for 2 seconds
    const checkForErrorTimeout = setTimeout(() => {
      if (orderRestrictions === "error") {
        toast.error("An error occurred");
      }
    }, 2000); // 2 seconds after the component is rendered
    // Cleanup the timeout to prevent it from running if the component unmounts
    return () => clearTimeout(checkForErrorTimeout);
  }, [orderRestrictions]);

  if(!mergedOrderItems){
    mergedOrderItems = cartItems;
  }
  
  useEffect(() => {
    // Load the JotForm script
    const script = document.createElement('script');
    script.src = "//guarantee-cdn.com/Web/Seal/gjs.aspx?SN=967143389"; // Replace with your JotForm URL
    script.async = true;
    document.body.appendChild(script);
    
    //@ts-ignore
    if (window._GUARANTEE && _GUARANTEE.Loaded) {
      //@ts-ignore
      window._GUARANTEE.Guarantee.order = cart.orderID;         
      //@ts-ignore
      window._GUARANTEE.Guarantee.subtotal = cart.calculateSubTotal;   
      //@ts-ignore
      window._GUARANTEE.Guarantee.currency = "USD";         
      //@ts-ignore
      window._GUARANTEE.Guarantee.email = "vjystvk@gmail.com"; 
      //@ts-ignore
      window._GUARANTEE.WriteGuarantee();  

    }

    return () => {
      // Remove the script when the component unmounts
      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <div className="card my-4 border-0">
      <div className="card-header bg-white py-0">
        <h3>{title}</h3>
      </div>
      <div className="card-body py-0">
      {/* @ts-ignore */}
        {mergedOrderItems?.map((orderItem, key) => {
        
          const childBundleItems = orderItems?.filter((item) => item?.parentOrderItemID === orderItem.orderItemID);
          let isLastItem = false;
          if(key+1 === mergedOrderItems.length && mergedOrderItems.length === 1){
            isLastItem=true
          }
          return (
            <CartModule.CoreComponents.CartLineItem
              key={`${orderItem.orderItemID}-${key}`}
              orderItem={orderItem}
              childBundleItems={childBundleItems}
              isRemovingItem={isRemovingItem}
              onRemoveItem={mergedOrderItems.length === 1 ? onClearCartData : onRemoveItem }
              onUpdateQty={onUpdateQty} 
              onClearCartData={onClearCartData}
              isLastItem={isLastItem}
            />
          );
        })}
      </div>
    </div>
  );
};