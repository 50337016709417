import { useState, useEffect } from "react";
import "./shippingInsurance.css"
import { useSelector, useDispatch } from "react-redux";
import InsuranceImg from "../../assets/Images/shipping-insurance-image.png"
import { useTranslation } from "react-i18next";
import { Modal } from "../../components/Modal/Modal";
import { axios } from "@ultracommerce/react-storefront/global";
import { receiveCart } from "@ultracommerce/react-storefront/global";

const serviceDomain = process.env.REACT_APP_API_URL;
    
const ShippingInsurance = ({value}) => {
    const dispatch = useDispatch();
    const cart = useSelector((state: any) => state.cart);
    const [isEnabled,setIsEnabled] = useState(true);
    const { t } = useTranslation();
    const [ShippingInsuranceModal, setShippingInsuranceModal] = useState(false);

    const UpdateShippingInsuranceHandler = async (value) => {
      try {
        const servicePath = "api/scope/updateShippingInsuranceFlag";
        const { data } = await axios(serviceDomain + servicePath, {
          data: {"shippingInsuranceFlag":value},
          withCredentials: true,
          method: "POST",
        });
        setIsEnabled(value);
        dispatch(receiveCart(data?.cart));
      } catch (err) {
        console.warn(err);
      }
    }
    
    useEffect(()=>{
        setIsEnabled(cart?.shippingInsuranceFlag);
    },[cart?.shippingInsuranceFlag]);

      return (
        <>
         
            <div className = "shipping-insurance-container">
                <div>
                    <img src={InsuranceImg} className="insurance-img" alt="Logo"/>
                </div>
                <div>
                    <h4>Your Shipment is protected by our guarantee</h4>
                    <div>
                        {isEnabled ?
                            <>
                                <span> Don't want to protect your shipment? </span>
                                <button className="btn btn-link link-btn remove-btn" onClick={() =>setShippingInsuranceModal(true)}>
                                  {t("frontend.remove")}
                                </button>
                                 <span className= "price-text">${Number(cart?.calculatedShippingInsuranceTotal || 0).toFixed(2)}</span>
                            </>
                            :
                            <>
                                <span> Do you want to protect your shipment? </span>
                                <button className="btn btn-link link-btn remove-btn" onClick={()=> {UpdateShippingInsuranceHandler(true)}}>
                                 {t("frontend.add")} 
                                 </button>
                            </>
                        }
                    </div>
                </div>
                <Modal
                show={ShippingInsuranceModal}
                setShow={setShippingInsuranceModal}
                title={<div className="d-flex justify-content-between"><p className= "m-0" >Are you sure you don’t want to protect your purchase?</p> <div className="mb-2 mb-sm-0 " onClick={()=> setShippingInsuranceModal(false)}> <i className="bi bi-x"></i> </div></div>}
                backgroundstatic={true}>
                    <h6><p>Our shipping protection offers 100% peace of mind throughout the shipping process</p>
                     <p> Effortless Claims: If something happens to your order between the time it leaves our warehouse and the moment you receive it, you can submit an instant claim. An email following your purchase will pre-fill your info, helping you file in a single click. No returns or follow-ups necessary.</p>
                     <p> Our team at Ammo Planet promises to protect your purchase and take every precaution as we prepare it for shipment. However, we also highly encourage you to purchase shipping insurance during your transaction. Ammo Planet is not liable for any physical damage to parcels or freight during transit, including theft or non-delivery, and buyers assume all risks for uninsured products. By purchasing shipping insurance, you will be certain of having coverage for any damage or loss of your property.</p>
                    </h6>
                      <span className="d-flex flex-wrap mb-2 mb-sm-0 justify-content-around mt-3"><a className="text-terms" href="/terms-and-conditions" target="_top" rel="noopener noreferrer">See Our terms And Conditions</a></span>
                    <div className="d-flex flex-wrap mb-2 mb-sm-0 justify-content-around mt-3">
                        <button className="btn btn-primary" onClick={()=> {setShippingInsuranceModal(false);UpdateShippingInsuranceHandler(false)}}>I UNDERSTAND</button>
                    </div>
                </Modal>
            </div> 
    </>
    )
};
export {ShippingInsurance };