import { GRID, LISTING } from "@ultracommerce/react-storefront/global/src/modules/ProductListing/productListing.moduleModels";
import { SearchListingModel } from "@ultracommerce/react-storefront/global/src/modules/ProductListing/Components/SearchListing/SearchListing.componentModel";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { useState } from 'react'
import { useSelector } from "react-redux";
import {Modal} from '../../../../components/Modal/Modal'
import { useNavigate, Link } from 'react-router-dom'
import { getProductRoute } from "@ultracommerce/react-storefront/global";

export const SearchListingView = ({
  componentData: {
    hide,
    viewMode,
    showFilterSidebar,
    showForcedPreFilters,
    searchConfig,
    searchListingData,
    cardDisplayConfigurations,
  },
  setViewMode,
}: {
  componentData: SearchListingModel;
  setViewMode: (mode: any) => void;
}) => {
  let {
    records,
    isFetching,
    potentialFilters,
    total,
    totalPages,
    sortBy,
    setSort,
    updateAttribute,
    setPage,
    params,
  } = searchListingData;
  const {
    ProductListingModule,
    CommonModule: { ListingPagination, SimpleImage },
    ProductModule
  } = useElementContext();
  const [updateQtyModal, setUpdateQtyModal] = useState<any>(false);
  const [itemData, setItemData] = useState<any>({})
  const [updateQtyError, setUpdateQtyError] = useState<any>("")
  const navigate = useNavigate();
  const productRouting = useSelector(getProductRoute);

  if (showForcedPreFilters) {
    return (
      <div className="container cetSearchPreFilter">
        {searchConfig?.requiredPreFilters?.map((filter: string, idx: number) => {
          return (
            <ProductListingModule.CoreControl.SearchPreFilter
              key={idx}
              params={params}
              potentialFilters={potentialFilters}
              requiredFilterSlug={filter}
            />
          );
        })}
      </div>
    );
  }
  
  return (
    <div className="container product-listing mb-5 py-2 px-0">
      <ProductListingModule.CoreControl.ListingToolBar
        sortBy={sortBy}
        setSort={setSort}
        recordsCount={total}
        searchConfig={searchConfig}
      />
      <ProductListingModule.CoreControl.ListingViewToggle
        config={searchConfig}
        viewMode={viewMode}
        setViewMode={setViewMode}
      />
      <div className="row">
        {(showFilterSidebar || showFilterSidebar !== false) && (
          <ProductListingModule.CoreControl.ListingSidebar
            config={searchConfig}
            isFetching={isFetching}
            hide={hide}
            filtering={potentialFilters}
            recordsCount={total}
            updateAttribute={updateAttribute}
          />
        )}
        <div className="col">
          {viewMode === LISTING && (
            <ProductListingModule.CoreControl.ListingListView
              cardDisplayConfigurations={cardDisplayConfigurations}
              config={searchConfig}
              isFetching={isFetching}
              pageRecords={records}
            />
          )}
          {viewMode === GRID && (
            <ProductListingModule.CoreControl.ListingGridView
              cardDisplayConfigurations={cardDisplayConfigurations}
              config={searchConfig}
              isFetching={isFetching}
              pageRecords={records.map((record: any)=>{
                const newRec = record 
                newRec.sku = {}
                newRec.sku.setItemData=setItemData
                newRec.sku.setUpdateQtyModal = setUpdateQtyModal
                newRec.sku.setUpdateQtyError =setUpdateQtyError
                return newRec;
              })}
            />
          )}
        </div>
      </div>
      <ListingPagination
        recordsCount={total}
        currentPage={params["currentPage"]}
        totalPages={totalPages}
        setPage={setPage}
      />
      <Modal show={updateQtyModal} setShow={setUpdateQtyModal} size="large" title="These item(s) may not have been added" backgroundstatic={true}>
      { 
        updateQtyError && <h3 className="bg-light text-danger d-flex text-center align-items-center p-3">{updateQtyError}</h3>
      }
      
      
        {itemData &&
          <div className="row py-3 cart-item-box">
            { itemData.sku && <div className="row detail">
              <div className="col-sm-2 col-3 image">
                {itemData?.sku?.imageFile && (
                  <SimpleImage
                    className="img-fluid  m-auto image_container productImage"
                    src={itemData?.sku?.imageFile}
                    alt={itemData?.sku?.product_productName}
                    type="product"
                  />
                )}
              </div>
              <div className="col-sm-4 col-9">
                <div className="title">
                  <h4>
                    <Link
                      to={{
                        pathname: `/${productRouting}/${itemData?.sku?.product_urlTitle}`,
                        // @ts-ignore
                        state: { ...itemData?.sku},
                      }}
                      className="text-decoration-none text-dark"
                    >
                      {itemData?.sku?.product_productName}
                    </Link>
                  </h4>
                </div>
                <div className="font-size-sm item-sku">
                  <span className="mr-2">SKU : {itemData?.sku?.skuCode}</span>
                </div>
                
               
              </div>
              <div className="col-sm-12 col-md-6 d-flex bottom-detail-box d-none d-md-block">
                <div className="row">
                  <div className="item-price col-sm-3">
                    <ProductModule.CoreComponents.ProductPrice
                      type="cart"
                      salePrice={itemData?.sku?.salePrice}
                      listPrice={itemData?.sku?.listPrice}
                    />
                  </div>
                </div>
              </div>
            </div>}
            
          </div>
        }
        <div className="d-flex justify-content-around mt-3"> 
          <button className="btn btn-primary mw-25 w-25" onClick={()=>setUpdateQtyModal(false)}>
            Continue Shopping
          </button>
          <button className="btn btn-primary mw-25 w-25" onClick={()=>navigate("/shopping-cart")}>
            Go to Cart 
          </button>
        </div>
    </Modal>
    </div>
  );
};
