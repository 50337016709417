import { PropsWithChildren, useState } from "react";
import { DynamicFormProps, Payload } from "@ultracommerce/react-storefront/global/src/components/DynamicForm/interface";
import { axios } from "@ultracommerce/react-storefront/global";
import { useFormik } from "formik";
import * as Yup from "yup";

const serviceDomain = process.env.REACT_APP_API_URL;

export interface SubscribeFormProps {
  actionButtonText: string;
  bootStrapLayoutClass: string;
  innerElements: [DynamicFormProps];
  successMessage: string;
  title: string;
}

const SubscribeForm = ({
  title,
  innerElements,
  actionButtonText,
  successMessage,
  bootStrapLayoutClass,
}: PropsWithChildren<SubscribeFormProps>) => {

  const [isFormSubmitted, setIsFormSubmitted] = useState<Payload | null>(null);
  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid email").required("Required")
    }),
    onSubmit: (values) => {
      try {
        const ucFormProps = innerElements[0];
        const emailPayload = ucFormProps.formQuestions.reduce<Payload>((acc, cur) => {
          if (cur.inputType === "email") {
            acc[cur.attributeCode] = values.email;
          }
          return acc;
        }, {});

        const servicePath = "/api/scope/addMembersToList";
        axios(serviceDomain + servicePath, {
          data: { "email": emailPayload?.emailAddress },
          withCredentials: false,
          method: "POST",
        }).then(res => {
          if (res?.data?.successfulActions?.length > 0 ) {
            let timeStamp = '';
            if(JSON.parse(localStorage.getItem("newsletteremailjson") || '{}')['email'] === emailPayload?.emailAddress){
                timeStamp = JSON.parse(localStorage.getItem("newsletteremailjson") || '{}')['timeStamp'];
            }
            localStorage.setItem('newsletteremailjson', JSON.stringify({email:emailPayload?.emailAddress, timeStamp: timeStamp || ""}));
          }
          setIsFormSubmitted(emailPayload);
        });

      } catch (err) {
        console.warn(err);
      }

    },
  });

  return (
    <div className={`p-4 cetSubscribeForm mx-auto ${bootStrapLayoutClass}`}>
      <h2 className="text-center pb-4">{title}</h2>
      {isFormSubmitted ? (
        <div className="text-center" dangerouslySetInnerHTML={{ __html: successMessage }} />
      ) : (
        <>
          <form className="d-flex cetSubscribeForm__form" onSubmit={formik.handleSubmit}>
            <input
              name="email"
              type="email"
              className="form-control"
              placeholder="Enter your email to subscribe to our newsletter"
              aria-label="email"
              autoComplete="email"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <div className="input-group-append">
              <button className="btn btn-sm btn-primary" type="submit">
                {actionButtonText}
              </button>
            </div>
          </form>
          {formik.errors.email && <span className="form-error-msg">{formik.errors.email}</span>}
        </>
      )}
    </div>
  );
};

export default SubscribeForm;