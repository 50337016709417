import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getProductRoute } from "@ultracommerce/react-storefront/global";
import { SearchSku } from "@ultracommerce/react-storefront/global/src/interface/SearchProduct";
import { Sku } from "@ultracommerce/react-storefront/global/src/interface/Product";
import { useElementContext } from "@ultracommerce/react-storefront/global";

interface ProductCardButtonProps {
  sku: SearchSku | Sku;
  isFetching: boolean;
  onAddToCart: () => void;
  listingButtonLabel: string;
  disableListingButton?: boolean;
  type?: string;
  shouldDisplayAuthenticationRequiredMessageFlag?: boolean;
}

const AuthenticateRequiredMessage = () => {
  const { t } = useTranslation();
  return (
    <div className="alert alert-warning fs-6" role="alert">
      {t("frontend.product.loginToAdd")}
      <br />
      <Link to="/my-account/login"> {t("frontend.account.login")} </Link>
    </div>
  );
};

const QuoteButton = ({
  isFetching,
  sku,
  listingButtonLabel,
}: Omit<ProductCardButtonProps, "type" | "shouldDisplayAuthenticationRequiredMessageFlag">) => {
  const [quoteModal, setQuoteModal] = useState(false);
  const { ProductModule } = useElementContext();
  return (
    <>
      <div className="d-flex">
        <span
          className="text-primary"
          onClick={(e) => {
            e.preventDefault();
            setQuoteModal(true);
          }}
          aria-disabled={isFetching}
          role="button"
        >
          {listingButtonLabel}
        </span>
      </div>
      {quoteModal && (
        <ProductModule.CoreComponents.AddProductToQuoteModal
          skuID={sku.skuID || ""}
          quantity={1}
          setQuoteModal={setQuoteModal}
        />
      )}
    </>
  );
};

const ListButton = ({
  isFetching,
  sku,
  listingButtonLabel,
}: Omit<ProductCardButtonProps, "type" | "shouldDisplayAuthenticationRequiredMessageFlag">) => {
  const [listModal, setListModal] = useState(false);
  const { ProductModule } = useElementContext();
  return (
    <>
      <div className="d-flex">
        <span
          className="text-primary"
          onClick={(e) => {
            e.preventDefault();
            setListModal(true);
          }}
          role="button"
          aria-disabled={isFetching}
        >
          {listingButtonLabel}
        </span>
      </div>
      {listModal && <ProductModule.CoreComponents.AddProductToListModal sku={sku} setListModal={setListModal} />}
    </>
  );
};

const ViewButton = ({
  sku,
  listingButtonLabel,
}: Omit<ProductCardButtonProps, "type" | "shouldDisplayAuthenticationRequiredMessageFlag">) => {
  const producturl = useSelector(getProductRoute) as string;
  const productLink = `/${producturl}/${sku.product_urlTitle}` + (sku.skuID?.length ? `?skuid=${sku.skuID}` : "");

  return (
    <Link to={productLink} className="text-primary text-decoration-none">
      {listingButtonLabel}
    </Link>
  );
};

const AddToCardButton = ({
  isFetching,
  onAddToCart,
  disableListingButton = false,
  listingButtonLabel,
  sku,
}: Omit<ProductCardButtonProps, "type" | "shouldDisplayAuthenticationRequiredMessageFlag">) => {
  const {
    CommonModule: { Button },
  } = useElementContext();
  return (
    <Button
      disabled={
        isFetching ||
        disableListingButton ||
        !sku?.settings?.skuAllowAddToCartFlag ||
        (!sku?.salePrice && !sku?.listPrice)
      }
      isLoading={isFetching}
      classList="btn btn-primary atc-button flex-grow-1"
      label={listingButtonLabel}
      onClick={(e) => {
        e.preventDefault();
        onAddToCart();
      }}
    />
  );
};

const FileButton = () => {
  return (
    <Link to="link-to-file" className="text-primary text-decoration-none">
      Link to File
    </Link>
  );
};

const ProductCardButton = ({
  type = "ADD_TO_CART",
  shouldDisplayAuthenticationRequiredMessageFlag = false,
  ...rest
}: ProductCardButtonProps) => {
  if (shouldDisplayAuthenticationRequiredMessageFlag) return <AuthenticateRequiredMessage />;

  if (type === "VIEW") return <ViewButton {...rest} />;
  if (type === "ADD_TO_CART") return <AddToCardButton {...rest} />;
  if (type === "ADD_TO_QUOTE") return <QuoteButton {...rest} />;
  if (type === "ADD_TO_LIST") return <ListButton {...rest} />;
  if (type === "PRODUCT_ATTRIBUTE_FILE") return <FileButton />;
  return null;
};

export { ProductCardButton, type ProductCardButtonProps };
