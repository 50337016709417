import { CartLineItemView } from "./CartLineItem.componentView";
import { useComponentData } from "@ultracommerce/react-storefront/global/src/modules/Cart/Components/CartLineItem/CartLineItem.componentService";
import { CartLineItemProps } from "@ultracommerce/react-storefront/global/src/modules/Cart/Components/CartLineItem/CartLineItem.componentModel";

const CartLineItem = (props: any) => {
  const { componentData, componentService } = useComponentData(props);
  const {onClearCartData,isLastItem} = props
  return (
    <CartLineItemView
      componentData={componentData}
      onRemoveItem={componentService.onRemoveItem}
      setItemCount={componentService.setItemCount}
      onUpdateQty={componentService.onUpdateQty}
      onClearCartData={onClearCartData} 
      isLastItem={isLastItem}
    />
  );
};
export { CartLineItem, type CartLineItemProps };
