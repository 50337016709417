import { useComponentData } from "@ultracommerce/react-storefront/global/src/pages/Cart/Cart.componentService";
import View from "./Cart.componentView";
import {useDispatch, useSelector} from 'react-redux'
import {updateItemQuantity} from '../../actions/cartActions'
import {useCallback, useState} from 'react'
import {Modal} from '../../components/Modal/Modal'
import { useNavigate, Link } from 'react-router-dom'
import { getProductRoute } from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { useFormatCurrency } from "@ultracommerce/react-storefront/global";

const CartController = () => {
  const { componentData, componentService } = useComponentData();
  const dispatch = useDispatch();
  const [updateQtyModal, setUpdateQtyModal] = useState<any>(false);
  const [itemData, setItemData] = useState<any>({})
  const [updateQtyError, setUpdateQtyError] = useState("")
  const cart = useSelector((state:any)=> state.cart)
  const orderItems = cart?.orderItems
  const productRouting = useSelector(getProductRoute);
  const [formatCurrency] = useFormatCurrency({});
  const {
    ProductModule,
    CommonModule: { SimpleImage },
  } = useElementContext();
  const onUpdateQty = useCallback(
    (orderItemID: string, itemCount: number) => {
      dispatch(updateItemQuantity(orderItemID, itemCount) as any).then((response: any)=>{
        if(response.errors){
          setUpdateQtyError(response.errors)
          setUpdateQtyModal(true)
          for(const orderItem in orderItems){
            if(orderItems[orderItem]?.orderItemID === orderItemID){
              setItemData(orderItems[orderItem])
            }
          }
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );
  const navigate = useNavigate()
  return (
    <>
    <View
      componentData={componentData}
      onClearCartData={componentService.onClearCartData}
      onRemoveItem={componentService.onRemoveItem}
      onUpdateQty={onUpdateQty}
      onApplyCode={componentService.onApplyCode}
    />
    <Modal show={updateQtyModal} setShow={setUpdateQtyModal} size="large" title="These item(s) may not have been added" backgroundstatic={true}>
      { 
        updateQtyError && typeof updateQtyError === "string" && <h3 className="bg-light text-danger d-flex text-center align-items-center p-3">{updateQtyError}</h3>
      }
      
      
        {itemData &&
          <div className="row py-3 cart-item-box">
            { itemData.sku && <div className="row detail">
              <div className="col-sm-2 col-3 image">
                {itemData?.sku?.images && itemData?.sku?.images?.length > 0 && (
                  <SimpleImage
                    className="img-fluid  m-auto image_container productImage"
                    src={itemData?.sku?.images?.at(0)}
                    alt={itemData?.sku?.product.productName}
                    type="product"
                  />
                )}
              </div>
              <div className="col-sm-4 col-9">
                <div className="title">
                  <h4>
                    <Link
                      to={{
                        pathname: `/${productRouting}/${itemData?.sku?.product.urlTitle}`,
                        // @ts-ignore
                        state: { ...itemData?.sku?.product },
                      }}
                      className="text-decoration-none text-dark"
                    >
                      {itemData?.sku?.product.productName}
                    </Link>
                  </h4>
                </div>
                <div className="font-size-sm item-sku">
                  <span className="mr-2">SKU : {itemData?.sku?.skuCode}</span>
                </div>
                
               
              </div>
              <div className="col-sm-12 col-md-6 d-flex bottom-detail-box d-none d-md-block">
                <div className="row">
                  <div className="item-price col-sm-3">
                    <ProductModule.CoreComponents.ProductPrice
                      type="cart"
                      salePrice={itemData?.extendedUnitPriceAfterDiscount}
                      listPrice={itemData?.sku?.listPrice}
                    />
                  </div>
                  { 
                    <div className="col-sm-4">
                      <small>Qty: </small> {itemData.quantity}
                    </div>
                  }
                  <div className="col-sm-3">
                    <h5 className="total-price">{formatCurrency(itemData?.extendedPriceAfterDiscount)}</h5>
                  </div>
                </div>
              </div>
            </div>}
            
          </div>
        }
        <div className="d-flex justify-content-around mt-3"> 
          <button className="btn btn-primary mw-25 w-25" onClick={()=>navigate('/')}>
            Continue Shopping
          </button>
          <button className="btn btn-primary mw-25 w-25" onClick={()=>navigate(0)}>
            Go to Cart 
          </button>
        </div>
    </Modal>
    </>
  );
};

export default CartController;
