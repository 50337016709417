import { SwRadioSelect } from "../../../SwRadioSelect/SwRadioSelect";
import { useState } from "react";

const FFLDealersList = ({records, onSelect}) => {

    const [selectedFFLDealer, setSelectedDealer] = useState("");
    const list = records.fflDealersRecords?.pageRecords?.map((record, index)=>({...record, name: record?.licName, address: `${record?.premiseStreet}, ${record?.premiseCity}, ${record?.premiseState}, ${record?.premiseZipCode}`, value: record?.fFLDealersID, voicePhone: record?.voicePhone, publishedFlag:true}))
    return (
    <ul>
        <SwRadioSelect
          options={list}
          onChange={(value) => {
            setSelectedDealer(value);
            onSelect(value);
          }}
          selectedValue={selectedFFLDealer}
        />
    </ul>
    );
};
export {FFLDealersList };