const FormTextField = ({
  attributeName,
  attributeCode,
  requiredFlag,
  attributeHint,
  setValue,
  value = "",
  inputType,
  row = 5,
  invalid,
  validationMessage,
}) => {
  return (
    <>
      <label htmlFor={attributeCode} className={`form-label ${requiredFlag ? "required" : ""}`}>
        {attributeName}
      </label>
      {inputType !== "textArea" && (
        <input
          className={"form-control" + (invalid ? " is-invalid" : "")}
          id={attributeCode}
          type={inputType}
          value={value}
          onChange={(e) => {
            setValue((response) => {
              return {
                ...response,
                [attributeCode]: e.target.value,
              };
            });
          }}
        />
      )}
      {inputType === "textArea" && (
        <textarea
          row={row}
          className={"form-control" + (invalid ? " is-invalid" : "")}
          id={attributeCode}
          value={value}
          onChange={(e) => {
            setValue((response) => {
              return {
                ...response,
                [attributeCode]: e.target.value,
              };
            });
          }}
        />
      )}
      {
        attributeHint && (
          <small class="text-muted" style={{fontSize: '0.72rem'}}>{attributeHint}</small>  
        )
      }
      {invalid && <div className="invalid-feedback">{validationMessage ?? ""}</div>}
    </>
  );
};

export default FormTextField;
