import { CancelTokenSource } from "axios";
import queryString from "query-string";
import { ProductFactory } from "@ultracommerce/react-storefront/global/src/factories/ProductFactory";
import { ProductSearchFactory } from "@ultracommerce/react-storefront/global/src/factories/ProductSearchFactory";
import { axios } from "@ultracommerce/react-storefront/global";
import { SearchProduct } from "@ultracommerce/react-storefront/global/src/interface/SearchProduct";
const serviceDomain = process.env.REACT_APP_DATASTORE_URL;

const getSearchConfig = () => ({
  productTargetConfig: window.UC?.integrations?.search.productSearchConfigConcern,
  typeaheadTargetConfig: window.UC?.integrations?.search.typeaheadSearchConfigConcern,
});

export class ProductService {
  productFactory!: typeof ProductFactory;
  productSearchFactory!: typeof ProductSearchFactory;
  constructor({ productFactory = ProductFactory, productSearchFactory = ProductSearchFactory } = {}) {
    this.productFactory = productFactory;
    this.productSearchFactory = productSearchFactory;
  }

  async getProduct(urlTitle: string, options: { source?: CancelTokenSource; processingSettings?: any } = {}) {
    try {
      let url = `${serviceDomain}/product/getProduct?urlTitle=${urlTitle}&storeTarget=${window.UC?.integrations?.datastore}`;
      if (process.env.REACT_APP_DELTA_STORE_URL) {
        url = `${process.env.REACT_APP_DELTA_STORE_URL}/public/ultracommerce/product/transform/byUrlTitle/${urlTitle}`;
      }
      const { data } = await axios(url);
      if (!data) throw new Error();

      return new this.productFactory({ product: data, attributeSets: {} } as any, options.processingSettings);
    } catch {
      return { isError: true, message: "Error fetching product" + urlTitle + " from Ultra Commerce API" };
    }
  }

  formatSearchQuery = (searchData: any, payload: any) => {
    if (payload.hasOwnProperty("sort") && payload.sort) {
      searchData.sort = payload.sort;
    }

    Object.keys(payload).forEach((key) => {
      if (key.startsWith("facet_")) {
        let value = Array.isArray(payload[key]) ? payload[key].join() : payload[key];
        if (value !== "") {
          if ("facet_category" === key) key = key + "UrlTitle";
          if ("facet_brand" === key && window.location.pathname.startsWith('/brand/')) {
            searchData.filters.push({
              key: 'brandUrlTitle',
              value
            })
            return
          }
          if ("facet_productType" === key) key = key + "UrlTitlePath";
          searchData.facets.push({ key: key.replace("facet_", ""), value });
        }
      }
      if (key.startsWith("filter_")) {
        let value = Array.isArray(payload[key]) ? payload[key].join() : payload[key];
        if (value !== "") {
          searchData.filters.push({ key: key.replace("filter_", ""), value });
        }
      }
    })

    // if (!searchData.facets.find((facet: any) => facet.key === 'inStock')) {
    //   searchData.facets.push({ key: 'inStock', value: 'Yes' }) // force inStock
    // }
  };

  async search(params: any, type: "product" | "sku", options: { source?: CancelTokenSource } = {}) {
    const servicePath = `/search/query/${type}`;
    const payload = { ...params };
    if (payload.keyword) {
      payload["searchTerm"] = payload.keyword;
    }

    if (payload.currentPage) {
      payload["pageFrom"] = payload.currentPage;
    }

    delete payload["keyword"];
    delete payload["currentPage"];

    if (process.env.REACT_APP_DELTA_API_URL) {
      const searchData: any = {
        facets: [],
        filters: [],
        query: payload.searchTerm,
        pageFrom: payload.pageFrom,
        route: window.location.pathname,
      };
       if(!('facet_inStock' in payload) )  {
         payload['facet_inStock'] = 'Yes'
       }
      this.formatSearchQuery(searchData, payload);
      const splitPath = window.location.pathname?.split("/")
      if (searchData.facets?.length || (splitPath[1] !== 'category' && splitPath[1] !== 'product-type')) {
        const { data } = await axios(`${process.env.REACT_APP_DELTA_API_URL}/api/v1/public/search`, {
          data: searchData,
          method: "POST",
          cancelToken: options.source?.token,
        });
        
        // hydrate products
        let fetchProducts: SearchProduct[] = await Promise.all(
          data.products.map(async (product: any) => {
            return fetch(product.productDataURL).then((resp) => resp.json()).catch((e) => product);
          }),
        );
        data.products = fetchProducts;
        return new this.productSearchFactory(data);
      }
    } else {
      const { data } = await axios(
        serviceDomain +
        servicePath +
        `?${queryString.stringify(
          { concernTarget: getSearchConfig().productTargetConfig, ...payload },
          {
            arrayFormat: "comma",
          },
        )}`,
        {
          cancelToken: options.source?.token,
        },
      );
      return new this.productSearchFactory(data);
    }
  }

  searchTypeahead(
    searchTerm: string,
    typeToSearch: "product" | "sku" | "category" | "productType",
    options: { source?: CancelTokenSource } = {},
  ) {
    if (process.env.REACT_APP_DELTA_API_URL) {
      return axios(`${process.env.REACT_APP_DELTA_API_URL}/api/v1/public/search/typeahead`, {
        data: {
          searchTerm,
        },
        withCredentials: false,
        method: "POST",
        cancelToken: options.source?.token,
      }).then((resp) => {
        return {
          ...resp,
          data: {
            items: resp.data.items.map((item: any) => {
              if (!item.metadata.skuID) item.metadata.skuID = "na";
              return item;
            }),
          },
        };
      });
    } else {
      const servicePath = `/search/typeahead`;
      return axios(
        serviceDomain +
        servicePath +
        `?${queryString.stringify(
          { concernTarget: getSearchConfig().typeaheadTargetConfig, searchTerm, typeToSearch },
          {
            arrayFormat: "comma",
          },
        )}`,
        {
          cancelToken: options.source?.token,
        },
      );
    }
  }
}
