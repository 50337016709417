import ContentLoader from "react-content-loader";
import { ProductCardConfiguration, SearchConfig, SkuCardConfiguration } from "@ultracommerce/react-storefront/global/src/modules/ProductListing/productListing.moduleModels";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { SearchProduct, SearchSku } from "@ultracommerce/react-storefront/global/src/interface/SearchProduct";

const ListingGridLoader = () => (
  <ContentLoader viewBox="0 0 1200 500" className="listingGridLoader">
    <rect x="100" y="0" rx="8" ry="8" width="300" height="300" />
    <rect x="100" y="320" rx="0" ry="0" width="300" height="32" />
    <rect x="100" y="380" rx="0" ry="0" width="180" height="36" />

    <rect x="500" y="0" rx="8" ry="8" width="300" height="300" />
    <rect x="500" y="320" rx="0" ry="0" width="300" height="36" />
    <rect x="500" y="380" rx="0" ry="0" width="180" height="30" />

    <rect x="900" y="0" rx="8" ry="8" width="300" height="300" />
    <rect x="900" y="320" rx="0" ry="0" width="300" height="32" />
    <rect x="900" y="380" rx="0" ry="0" width="180" height="36" />
  </ContentLoader>
);

export interface ListingGridViewProps {
  cardDisplayConfigurations: {
    skuCardConfiguration: SkuCardConfiguration;
    productCardConfiguration: ProductCardConfiguration;
  };
  isFetching: boolean;
  pageRecords: SearchProduct[] | SearchSku[];
  config: SearchConfig;
}

const ListingGridView = ({ cardDisplayConfigurations, isFetching, pageRecords, config }: any) => {
  const { ProductCardModule, ProductListingModule } = useElementContext();

  if (isFetching) {
    return (
      <>
        <ListingGridLoader /> <ListingGridLoader /> <ListingGridLoader />
      </>
    );
  }

  if (pageRecords.length === 0)
    return <ProductListingModule.CoreControl.NoProductFound noProductFoundLink={config.noProductFoundLink} />;

  return (
    <div className="product-grid">
      {pageRecords?.map((product: any, index: any) => {
        return (
          <div key={index} className="mb-4">
            {config?.params?.productsListingFlag ? (
              <ProductCardModule.CoreControl.ProductCard
                cardConfiguration={cardDisplayConfigurations.productCardConfiguration}
                {...(product as SearchProduct)}
              />
            ) : (
              <ProductCardModule.CoreControl.SkuCard
                cardConfiguration={cardDisplayConfigurations.skuCardConfiguration}
                {...(product as SearchSku)}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
export { ListingGridView };
