import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PdpPageModel from "@ultracommerce/react-storefront/global/src/pages/ProductDetailsPage/ProductDetailsPage.pageModel";
import { PublicInterface } from "@ultracommerce/react-storefront/global/src/interface/common";
import { useUtilities } from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import useViewedProduct from '../../hooks/components/Klaviyo/useViewedProduct'


const ProductDetailDisplayView = ({
  productName,
  product,
  productDescription,
  helmet,
  isFetching,
  showLoginForPrice,
  additionalInformation,
  productDetailGallery,
  crumbs,
  dynamicContentElements,
  productPrice,
  productDetailHeading,
  productBundle,
  favoritesButton,
  skuOptions,
  skuSelector,
  productModifiers,
  productForm,
  productAttributes,
  relatedProductsSlider,
}: PublicInterface<PdpPageModel>) => {
  const {
    ProductModule,
    CommonModule: { DynamicComponent, Spinner, BreadCrumb },
  } = useElementContext();
  const { t } = useTranslation();
  const { eventHandlerForWSIWYG } = useUtilities();
  useViewedProduct(product);

  //@ts-ignore
  const attributeSets = product?.attributeSets?.filter((set) => ['productFacets', 'productSpecs'].includes(set.attributeSetCode))
  const attrs = attributeSets.map((set: any) => set.attributes)

  if (isFetching) {
    return (
      <div className="container mt-5">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="bg-light px-4 py-3 pt-4">
        <BreadCrumb crumbs={crumbs} />
      </div>
      {helmet && <Helmet {...helmet} />}
      <div className="container mt-5">
        {favoritesButton && ( //changes-favourites display control from config
          <div className="d-flex justify-content-end">
            <ProductModule.CoreComponents.HeartButton className="btn-wishlist mr-0 flex-end" {...favoritesButton} />
          </div>
        )}
        <div className="row">
          <div className="col-sm-12 col-lg-5 mb-4 mb-md-0">
            {productDetailGallery && <ProductModule.CoreComponents.ProductDetailGallery {...productDetailGallery} />}
            {/*changes-product description moved below the image slider */}


            {additionalInformation && (
              <ProductModule.CoreControl.ProductAdditionalInformation {...additionalInformation} />
            )}
          </div>
          <div className="col-sm-12 col-lg-6 offset-lg-1">
            <h3 className="fw-bold mb-3">{productName}</h3>
            {productDetailHeading && <ProductModule.CoreComponents.ProductDetailHeading {...productDetailHeading} />}
            {productPrice && (
              <div className="my-4 fw-bold">
                <ProductModule.CoreComponents.ProductPrice {...productPrice} />
              </div>
            )}
            {skuOptions && <ProductModule.CoreComponents.SkuOptions {...skuOptions} />}
            {skuSelector && <ProductModule.CoreComponents.SkuSelector {...skuSelector} />}
            {productModifiers && <ProductModule.CoreControl.ProductModifiers {...productModifiers} />}

            {productForm && (
              <div className="d-flex align-items-center">
                <ProductModule.CoreComponents.ProductForm {...productForm} />
              </div>
            )}

            {showLoginForPrice && ( //changes - config validation and alerts for showing trade price.
              <div className="alert alert-danger pdp-login-alert" role="alert">
                {t("frontend.loginforprice")}
                <br />
                <Link to="/my-account/login"> {t("frontend.account.login")} </Link>
              </div>
            )}

            {productAttributes && (
              <div className="row mb-4">
                <div className="col-md-12">
                  <ProductModule.CoreComponents.ProductAttributes {...productAttributes} />
                </div>
              </div>
            )}

            {dynamicContentElements?.map((rowEl: any, rowIdx: number) => <DynamicComponent el={rowEl} key={rowIdx} />)}
          </div>
        </div>
        {productDescription && (
          <>
            <h3>Product Description</h3>

            <div
              style={{ textAlign: 'justify' }}
              onClick={eventHandlerForWSIWYG}
              dangerouslySetInnerHTML={{
                __html: productDescription,
              }}
            />
          </>
        )}
        <h3>Specifications</h3>
        <table className="table-striped productDetail_table">
          <tbody>
            {attrs?.map(
              (setAttr: any) => {
                return setAttr?.map((attr) => {
                  if (Object.keys(product).includes(attr.attributeCode)) {
                    return (
                      <tr className="table-primary">
                        <td>{attr.attributeName}</td>
                        {attr.attributeCode !== 'upcCodeSpec' && (
                          <td>{product[attr.attributeCode]}</td>
                        )}
                        {attr.attributeCode === 'upcCodeSpec' && (
                          <td>{product[attr.attributeCode].toString().padStart(12, '0')}</td>
                        )}
                      </tr>
                    )
                  } else {
                    return <></>
                  }
                })

              }
            )}
          </tbody>
        </table>
        {productBundle && <ProductModule.CoreComponents.ProductBundle {...productBundle} />}
      </div>
      {/* changes - using config to validate display of relatedproducts and reviews */}
      {relatedProductsSlider && <ProductModule.CoreComponents.RelatedProductsSlider {...relatedProductsSlider} />}
    </>
  );
};

export default ProductDetailDisplayView;
