import { useContentContext } from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import {BlogPostBody} from "./BlogPostBody";

const BlogPost = () => {
  const pageData = useContentContext();
  const {
    PageModule: { DynamicPage },
    CommonModule: { BlogPostHeader },
  } = useElementContext();

  const { blogPostData } = pageData.templateData;

  return (
    <DynamicPage ignoreLayout={true}>
      {({ renderDynamicContent }) => (
        <>
          <BlogPostHeader />
          <div className="container my-5">
            {blogPostData && <BlogPostBody blogPostData={blogPostData} renderDynamicContent={renderDynamicContent}/>}
          </div>
        </>
      )}
    </DynamicPage>
  );
};
export { BlogPost };
