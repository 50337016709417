import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { isExternalURL } from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { getSdkURL } from "@ultracommerce/react-storefront/global";

const ListingBanner = ({
  heading = "",
  crumbs = undefined,
  images = [],
  description = "",
  subHeading = "",
  type = "brand",
  showCrumbs = true,
  isCollapsible = true,
}) => {
  const {
    CommonModule: { BreadCrumb },
  } = useElementContext();
  const { t } = useTranslation();
  const [imageMissing, setImageMissing] = useState(false);
  const host = getSdkURL();
  const image = images?.at(0);

  return (
    <div className="container-fluid d-flex flex-column justify-content-center p-0">
      <div className="row">
        {image && !imageMissing && (
          <div
            className={`col-xs-12 col-sm-3 col-md-3 col-lg-2 text-center banner-img collapse multi-collapse ${
              isCollapsible ? "" : "show"
            }`}
            id="collapseimage"
          >
            {images && (
              <img
                src={`${isExternalURL(image) ? "" : host}${images?.at(0)}`}
                alt={heading}
                style={{ maxHeight: "125px", border: "1px solid #dee2e6", background: "#fff" }}
                type={type}
                onError={() => setImageMissing(true)}
              />
            )}
          </div>
        )}
        <div
          className={`flex-grow-1 col-xs-12 col-sm-7 col-md-6 col-lg-8 d-flex flex-column justify-content-center ${
            isCollapsible ? "" : "flex-grow-1"
          }`}
        >
          <div className="row text-left">
            {type === "brand" && (
              <Link
                className={`text-uppercase small banner-collapse-link collapse multi-collapse pb-2 ${
                  isCollapsible ? "" : "show"
                }`}
                to="/brands"
                id="collapselink"
              >
                {t("frontend.product.shopByBrand")}
              </Link>
            )}

            <h2 className="display-6 align-content-start m-0 pt-0">{heading}</h2>
            {!!subHeading && <h3 className="h5 text-secondary m-0">{subHeading}</h3>}
          </div>

          <div
            className={`row text-left banner-desc collapse multi-collapse ${isCollapsible ? "" : "show"}`}
            id="collapsedesc"
          >
            <span className="mb-0 mt-2" dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>

        {isCollapsible && (
          <div className="d-flex mt-2 mt-md-3 ms-3 ms-sm-0 form-check form-switch col-lg-4 col-md-5 col-sm-5 justify-content-end banner-switch-div">
            <input
              type="checkbox"
              className="form-check-input listing-banner-switch"
              role="switch"
              id="flexSwitchCheckDefault"
              aria-expanded="false"
              data-bs-toggle="collapse"
              data-bs-target=".multi-collapse"
              aria-controls="collapseimage collapselink collapsedesc"
            />
          </div>
        )}
      </div>

      {showCrumbs && crumbs && (
        <div className="row pt-2">
          <div className="col-12 text-center">
            <BreadCrumb crumbs={crumbs} type={type}/>
          </div>
        </div>
      )}
    </div>
  );
};

export { ListingBanner };
