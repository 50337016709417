import { useState, useEffect } from "react";
import { axios } from "@ultracommerce/react-storefront/global";

const serviceDomain = process.env.REACT_APP_API_URL;

export const useFFLDealers = (zipCode, pageNumber, search ) => {
  
  let [data, setData] = useState({});
//   let [error, setError] = useState({ isError: false, message: "" });

  useEffect(() => {
    let source = axios.CancelToken.source();
    
    async function fetchFFLDetails (zipcode) {
        try{
          const servicePath = "/api/scope/getFFLDealers";
          const {data} = await axios (serviceDomain + servicePath, {
            data: {"zipCode":zipcode, "currentPage": pageNumber},
            withCredentials: false,
            method: 'POST',
          }
          );
          
          setData(data);
        }
        catch(err){
          setData(err);
        }
    }
    if(zipCode.length === 5 && search){
      fetchFFLDetails(zipCode);
    }
    return () => {
      source.cancel();
    };
  }, [zipCode, pageNumber, search]);
  return data;
};