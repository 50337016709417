import { Suspense, memo, useEffect, useMemo } from "react";
import { Routes as RouterRoutes, Route, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useSelector } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import { axios, useContentContext, useElementContext, useServiceContext } from "@ultracommerce/react-storefront/global";
import { useState } from "react";
import { AgeConsentModal } from "./components/Modal/AgeConsentModal";

const Routes = Sentry.withSentryReactRouterV6Routing(RouterRoutes);

function getUserBrowserAndOS() {
  var browserName = navigator.appName;
  var nameOffset, verOffset;
  var nAgt = navigator.userAgent;
  // In Opera, the true version is after "OPR" or after "Version"
  if ((verOffset = nAgt.indexOf("OPR")) !== -1) {
    browserName = "Opera";
  }
  // In MS Edge, the true version is after "Edg" in userAgent
  else if ((verOffset = nAgt.indexOf("Edg")) !== -1) {
    browserName = "Microsoft Edge";
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
    browserName = "Microsoft Internet Explorer";
  }
  // In Chrome, the true version is after "Chrome" 
  else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
    browserName = "Chrome";
  }
  // In Safari, the true version is after "Safari" or after "Version" 
  else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
    browserName = "Safari";
  }
  // In Firefox, the true version is after "Firefox" 
  else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
    browserName = "Firefox";
  }
  // In most other browsers, "name/version" is at the end of userAgent 
  else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
    (verOffset = nAgt.lastIndexOf('/'))) {
    browserName = nAgt.substring(nameOffset, verOffset);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  return { browserName }
}

// Function to set a cookie
function setCookie(name: string, value: string, days: any) {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
}

// Function to get a cookie
function getCookie(name: string) {
  const cookieValue = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
  return cookieValue ? cookieValue.pop() : null;
}

const hasAgeConsent = getCookie("ageConsent");

//https://itnext.io/react-router-transitions-with-lazy-loading-2faa7a1d24a
function App() {
  const pageData = useContentContext();
  const [ageConsentDialogue, setAgeConsentDialogue] = useState(true);
  const location = useLocation();
  const {
    PageModule,
    CommonModule: { Loading },
  } = useElementContext();

  const { RouteService } = useServiceContext();
  const state = useSelector((state) => state);
  const routeService = useMemo(() => new RouteService(), [RouteService]);
  const routes = useMemo(() => routeService.genRoutes(state), [routeService, state]);

  const user = useSelector((state: any) => state.userReducer);
  const serviceDomain = process.env.REACT_APP_API_URL;
  const emailAddress = user?.primaryEmailAddress?.emailAddress || JSON.parse(localStorage.getItem("newsletteremailjson") || '{}')['email'] || "";
  const { browserName } = getUserBrowserAndOS();

  useEffect(() => {
  if (emailAddress !== '') {
    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
    // Check if stored data exists and timestamp is of the same date
    if (emailAddress && JSON.parse(localStorage.getItem("newsletteremailjson") || '{}')['timeStamp'] !== currentDate) {
      let data = {
        event: "Active on Site",
        email: emailAddress,
        properties: {
          browser: browserName,
          page: window.location.href
        },
      };
      axios(serviceDomain + "api/scope/triggerEvent", {
        data,
        withCredentials: false,
        method: "POST",
      }).then((res) => console.warn("res", res));
      localStorage.setItem("newsletteremailjson", JSON.stringify({ email: emailAddress, timeStamp: currentDate }));
    }
  }
  // eslint-disable-next-line
}, [location]);

  if (pageData?.contentID === null || pageData?.contentID === undefined) return null;

  return (
    <>
      <Suspense fallback={<Loading />}>
        <ErrorBoundary
          FallbackComponent={PageModule.ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
        >
          <Routes>
            {routes.map((route, index) => {
              const PageComponent = PageModule[route.elementKey];
              return <Route key={index} path={route.path} element={<PageComponent />} {...route.props} />;
            })}
          </Routes>
        </ErrorBoundary>
      </Suspense>
      {!hasAgeConsent &&
        <AgeConsentModal show={ageConsentDialogue} setShow={setAgeConsentDialogue} size="medium" title="" backgroundstatic={true}>
          <h5>Thank you for your interest in Ammo Planet.</h5>
          <h5>Our site is for individuals 18 years of age or older.</h5>

          <h5 style={{ color: "red", marginTop: "20px" }}>Are you 18 years of age or older?</h5>
          <div className="d-flex flex-wrap mb-2 mb-sm-0 justify-content-around mt-3">
            <button className="btn btn-primary" onClick={() => window.location.href = "https://www.google.com"}>
              No
            </button>
            <button className="btn btn-primary" onClick={(event) => {
              setCookie("ageConsent", "true", 365);
              event.preventDefault()
              setAgeConsentDialogue(false)
            }}>
              Yes
            </button>
          </div>
        </AgeConsentModal>
      }

    </>
  );
}

export default memo(App);
