import "./SwRadioSelect.css";
const SwRadioSelect = ({ label, onChange, options = [], selectedValue, errorMsg }) => {
  return (
    <div className="form-group border-top p-1" style={{ maxHeight : "650px", overflowY: "auto" }}>
      {/* don't pass label for custom label */}
      {label && (
        <>
          <label className="w-100 h4">{label}</label>
        </>
      )}

       <div className="d-flex flex-column">
        {options.length > 0 ?
          options.map(({ value, name, code, voicePhone, address }) => {
            return (
              <div
                key={value}
                className="form-check form-check-inline custom-control custom-radio align-items-center d-inline-flex cursor-pointer fflDealer"
                style={{ zIndex: 0 }}
              >
                <input
                  className="custom-control-input"
                  style={{ cursor: "pointer"}}
                  type="radio"
                  id={code || value}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  checked={selectedValue === value}
                />
                <label className="d-flex flex-column p-1">
                  <label
                    className="py-1 ps-2 mb-0 custom-control-label fw-bold fs-7"
                    htmlFor={code || value}
                    style={{ letterSpacing: "1.1", cursor: "pointer" }}
                  >
                    {name}
                  </label>
                  <label
                    className="py-1 ps-2 mb-0 custom-control-label address-text"
                    htmlFor={code || value}
                    style={{ letterSpacing: "1.1", cursor: "pointer" }}
                  >
                    {address}
                  </label>
                  <label
                    className="py-1 ps-2 mb-0 custom-control-label address-text"
                    htmlFor={code || value}
                    style={{ letterSpacing: "1.1", cursor: "pointer" }}
                  >
                    Phone : {voicePhone}
                  </label>
                </label>
              </div>
            );
          }): <div>Please enter a different zipcode</div>}
      </div>
      {!!errorMsg && <span className="form-error-msg">{errorMsg}</span>}
    </div>
  );
};

export { SwRadioSelect };