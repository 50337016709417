import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import {
  ComponentContextProvider,
  ElementContextProvider,
  ServiceContextProvider,
  initApp,
  CommonModule,
  CartModule,
  ProductCardModule,
  ProductModule,
  PageModule,
  ProductListingModule,
  LogService
} from "@ultracommerce/react-storefront/global";
import {AppSwitcher} from './AppSwitcher' 
import "./i18n";
import { createReduxStore } from "./createStore";
import "./assets/theme";
import preload from "./preload";
import {AccountProfile} from './components/Account/AccountProfile/AccountProfile'
import {FulfilmentAddressSelector} from './components/Checkout/Fulfilment/FulfilmentAddressSelector'
import {ShippingSlide} from './components/Checkout/Fulfilment/Shipping'
import {ShippingFulfillment} from './components/Checkout/Fulfilment/ShippingFulfillment'
import {ShippingAddressDetails} from './components/Checkout/Review/ShippingAddressDetails'
import {BillingAddressDetails} from './components/Checkout/Review/BillingAddressDetails'
import {FulfillmentList} from './components/Checkout/Fulfilment/FulfillmentList'
import { CartLineItem } from "./modules/Cart/Components/CartLineItem/CartLineItem.component"
import { CartItemList } from "./modules/Cart/Controls/CartItemList"
import { DropdownButtons } from "./modules/ProductCard/Controls/DropdownButtons"
import { ProductMultiCartButtons } from "./modules/Product/Controls/ProductMultiCartButtons"
import { ProductCardActions } from './modules/ProductCard/Components/ProductCardActions/ProductCardActions.component'
import CartController from './pages/Cart/Cart.component'
import {ProductForm} from './modules/Product/Components/ProductForm/ProductForm.component'
import SubscribeForm from './components/SubscribeForm/SubscribeForm';
import {OrderSummary, CartOrderSummary} from './components/Checkout/OrderSummary'
import {MiniCart} from './components/Header/MiniCart'
import {HeaderContent} from './components/Header/HeaderContent'
import {ProductTypeList} from './modules/Product/Components/ProductTypeList/ProductTypeList.component'
import ProductType from './pages/ProductType/index'
import {SkuCard, ProductCard} from './modules/ProductCard/Controls/ProductCardCardView'
import {ProductSlider} from './components/ProductSlider/ProductSlider'
import {CategoryList} from './components/CategoryList/CategoryList'
import {SocialLinks} from './components/SocialLinks/SocialLinks'
import {SearchListing} from './modules/ProductListing/Components/SearchListing/SearchListing.component'
import {ListingGridView} from './modules/ProductListing/Controls/ListingGridView'
import {default as Category} from './pages/Category'
import { ProductService } from "./services/productService.class";
import {BlogPost} from './pages/Blog/BlogPost';
import {ListingBanner} from './components/ListingBanner/ListingBanner'
import {BreadCrumb} from './components/BreadCrumb/BreadCrumb'
import {DynamicProductListing} from './components/DynamicProductListing/DynamicProductListing'
import {ListingSidebar} from './modules/ProductListing/Controls/ListingSidebar'
import App from './App'
import ProductDetailsPage from "./pages/ProductDetailsPage/ProductDetailsPage.page";
import FormTextField from './components/DynamicForm/inputs/formTextField'
import {CreditCardDetails} from './components/Checkout/Payment/CreditCardDetails'
import {ProductCardButton} from './modules/ProductCard/Controls/ProductCardButton'

CommonModule.AccountProfile = AccountProfile
//@ts-ignore
CommonModule.FulfilmentAddressSelector = FulfilmentAddressSelector
CommonModule.ShippingAddressDetails = ShippingAddressDetails
CommonModule.BillingAddressDetails = BillingAddressDetails
CommonModule.ShippingSlide = ShippingSlide
//@ts-ignore
CommonModule.ShippingFulfillment = ShippingFulfillment
//@ts-ignore
CommonModule.FulfillmentList = FulfillmentList
//@ts-ignore
CommonModule.SubscribeForm = SubscribeForm
CommonModule.CartOrderSummary = CartOrderSummary;
CommonModule.OrderSummary = OrderSummary;
//@ts-ignore
CommonModule.HeaderContent = HeaderContent;
CommonModule.MiniCart = MiniCart;
CommonModule.SocialLinks = SocialLinks;
CartModule.CoreComponents.CartLineItem = CartLineItem;
CartModule.CoreControl.CartItemList = CartItemList;
ProductCardModule.CoreControl.DropdownButtons = DropdownButtons
ProductModule.CoreControl.ProductMultiCartButtons = ProductMultiCartButtons
ProductCardModule.CoreComponents.ProductCardActions = ProductCardActions
PageModule.Cart = CartController
ProductModule.CoreComponents.ProductForm = ProductForm
ProductModule.CoreComponents.ProductTypeList = ProductTypeList
PageModule.ProductType = ProductType;
ProductCardModule.CoreControl.SkuCard = SkuCard
CommonModule.ProductSlider = ProductSlider
CommonModule.CategoryList = CategoryList
ProductListingModule.CoreComponents.SearchListing = SearchListing
ProductListingModule.CoreControl.ListingGridView= ListingGridView
ProductCardModule.CoreControl.ProductCard = ProductCard
PageModule.Category = Category;
PageModule.BlogPost = BlogPost;
CommonModule.ListingBanner = ListingBanner
//@ts-ignore
CommonModule.BreadCrumb = BreadCrumb
CommonModule.DynamicProductListing = DynamicProductListing
ProductListingModule.CoreControl.ListingSidebar = ListingSidebar
CommonModule.App = App
PageModule.ProductDetailsPage = ProductDetailsPage;
CommonModule.FormTextField = FormTextField
CommonModule.CreditCardDetails = CreditCardDetails
ProductCardModule.CoreControl.ProductCardButton = ProductCardButton

const logService = new LogService()
const store = createReduxStore({logService})

initApp(preload);
createRoot(document.getElementById("app")!).render(
  <Provider store={store}>
    <Router>
      <ServiceContextProvider customServices = {{ProductService}}>
        <ElementContextProvider customComponents={{CommonModule, CartModule, ProductCardModule, ProductModule, PageModule}}>
          <ComponentContextProvider>
            <AppSwitcher />
          </ComponentContextProvider>
        </ElementContextProvider>
      </ServiceContextProvider>
    </Router>
  </Provider>
);