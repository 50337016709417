import { useState, useEffect } from "react";
import { axios } from "@ultracommerce/react-storefront/global";

const serviceDomain = process.env.REACT_APP_API_URL;

export const useFulfilmentFlag = (orderId, stateCode= "new", fflFlag, fulfillmentLength) => {
  let [isFetching, setFetching] = useState(true);
  let [data, setData] = useState(null);

  useEffect(() => {
    let source = axios.CancelToken.source();
    setFetching(true);
    async function fetchFFLFlagDetails() {
      if(stateCode === ""){
        // eslint-disable-next-line 
        stateCode = "new";
      }
      try {
        const servicePath = "/api/scope/getFFLDetailsForOrderFulfillments";
        const { data } = await axios(serviceDomain + servicePath, {
          data: {"orderId":orderId, "stateCode": stateCode},
          withCredentials: false,
          method: "POST",
        });
        setData(data?.data);
      } catch (err) {
        setData(err);
      }
      setFetching(false);
    }
    if(stateCode !== null && orderId!==null && (!fflFlag || (fflFlag && fulfillmentLength)) ){
      fetchFFLFlagDetails();
    }

    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, stateCode]);
  return {FULLFILLMENTFLAGDATA: data, isFetching};
};