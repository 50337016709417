import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useFormatCurrency } from "@ultracommerce/react-storefront/global";
import { isVatCountry, disableInteractionSelector } from "@ultracommerce/react-storefront/global";
import { useCallback } from "react";
import { removePromoCode } from "@ultracommerce/react-storefront/global";

const CartOrderSummary = () => {
  const dispatch = useDispatch();
  const cart: any = useSelector((state: any) => state.cart);
  const { t } = useTranslation();

  const onRemovePromoCode = useCallback(
    (promotionCode: string) => {
      dispatch(removePromoCode(promotionCode, undefined, t("frontend.cart.promo_code_removed")) as any);
    },
    [dispatch, t],
  );
  return <OrderSummary cart={cart} onRemovePromoCode={onRemovePromoCode} />;
};

const OrderSummary = ({
  cart,
  onRemovePromoCode,
}: {
  cart: Cart;
  onRemovePromoCode: (promotionCode: string) => void;
}) => {
  const [formatCurrency] = useFormatCurrency({});
  const disableInteraction: boolean = useSelector(disableInteractionSelector);
  const showVat: boolean = useSelector(isVatCountry);
  const { t } = useTranslation();

  return (
    <>
      <div className="card mb-4">
        <div className="card-header">
          <h4 className="mb-0">{t("frontend.cart.orderSummary")}</h4>
        </div>
        <ul className="list-group list-group-flush ">
          <li className="list-group-item d-flex justify-content-between ">
            <h6 className="my-0"> {t("frontend.checkout.subTotal")}</h6>
            <span className="float-end">
              <strong>
                {cart.subtotal > 0 ? formatCurrency(cart.subtotal) : "--"}
              </strong>
            </span>
          </li>
          <li className="list-group-item d-flex justify-content-between ">
            <h6 className="my-0"> {t("frontend.cart.shippingDelivery")}</h6>
            <span className="float-end">
              <strong>
                {cart.fulfillmentChargeAfterDiscountTotal > 0
                  ? formatCurrency(cart.fulfillmentChargeAfterDiscountTotal)
                  : "--"}
              </strong>
            </span>
          </li>
          {!showVat && (
            <li className="list-group-item d-flex justify-content-between">
              <h6 className="my-0">{t("frontend.cart.tax")}</h6>

              <span className="float-end">
                <strong>{cart.taxTotal > 0 ? formatCurrency(cart.taxTotal) : "--"}</strong>
              </span>
            </li>
          )}
          {cart.promotionCodes.length > 0 && (
            <>
              <li className="list-group-item d-flex justify-content-between bg-light">
                <div className="text-success">
                  <h6 className="my-0"> {t("frontend.cart.discount")}</h6>
                </div>
                <span className="float-end align-center">
                  <span className="text-success">
                    {cart.discountTotal > 0 ? formatCurrency(cart.discountTotal) : "--"}
                  </span>
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between bg-light">
                {cart.promotionCodes.map((promotionCodeItem: any) => {
                  //TODO: Review
                  const { promotionCode } = promotionCodeItem;
                  return (
                    <div key={promotionCode}>
                      <button
                        className="btn badge bg-success promo-btn"
                        type="button"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Remove Promotion"
                        key={promotionCode}
                        disabled={disableInteraction}
                        onClick={(event) => {
                          event.preventDefault();
                          onRemovePromoCode(promotionCode);
                        }}
                      >
                        <i className="bi bi-x"></i>
                        <span className="font-size-sm">{promotionCode}</span>
                      </button>
                    </div>
                  );
                })}
              </li>
            </>
          )}
           <li className="list-group-item d-flex justify-content-between">
            <h6 className="my-0">Shipping Insurance</h6>
            <strong>{cart?.calculatedShippingInsuranceTotal > 0 ? formatCurrency(cart?.calculatedShippingInsuranceTotal) : "--"}</strong>
          </li> 
          <li className="list-group-item d-flex justify-content-between">
            <h6 className="my-0">{t("frontend.cart.total")}</h6>
            <strong>{cart.total > 0 ? formatCurrency(cart.total) : "--"}</strong>
          </li>
          {showVat && (
            <li className="list-group-item d-flex justify-content-between ">
              <h6 className="my-0">{t("frontend.cart.vat")}</h6>
              <span className="float-end">
                <strong>{cart.VATTotal > 0 ? formatCurrency(cart.VATTotal) : "--"}</strong>
              </span>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export { OrderSummary, CartOrderSummary };
