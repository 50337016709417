import { SearchListingProps, SearchListingModel } from "@ultracommerce/react-storefront/global/src/modules/ProductListing/Components/SearchListing/SearchListing.componentModel";
import { SearchListingView } from "./SearchListing.componentView";
// import { useComponentData } from "@ultracommerce/react-storefront/global/src/modules/ProductListing/Components/SearchListing/SearchListing.componentService";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useListing } from "@ultracommerce/react-storefront/global";
import { LISTING } from "@ultracommerce/react-storefront/global/src/modules/ProductListing/productListing.moduleModels";

const SearchListing = (props: SearchListingProps) => {
  const defaultListingConfig = useSelector((state: any) => state.configuration.defaultListingConfig);
  const [preFilter] = useState(props.initPreFilter);
  const searchConfig = useMemo(
    () => JSON.parse(props?.listingConfig) || defaultListingConfig,
    [defaultListingConfig, props?.listingConfig],
  );
  if(searchConfig?.sortOptions === null){
    searchConfig.sortOptions = true
  }
  const searchListingData = useListing(preFilter, searchConfig);
  const [viewMode, setViewMode] = useState(searchConfig.viewMode || LISTING);
  const componentService = {setViewMode}
  const componentData = useMemo(
    () => new SearchListingModel({ props, searchConfig, searchListingData, viewMode }),
    [props, searchConfig, searchListingData, viewMode],
  );
  return <SearchListingView componentData={componentData} setViewMode={componentService.setViewMode} />;
};

export { SearchListing, type SearchListingProps };
