import { useState, useEffect } from "react";
import { FFLDealersList } from "./FFLDealersList";
import { useTranslation } from "react-i18next";
import { useFFLDealers } from "../../../../hooks/components/Checkout/useFFLDealers";
import { useElementContext, logout } from "@ultracommerce/react-storefront/global";
import { useAddFFLAddress } from "../../../../hooks/components/Checkout/useAddFFLAddress";
import { toast } from "react-toastify";
import {useRestrictions} from '../../../../hooks/components/Cart/useRestrictions'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import { Modal } from "../../../Modal/Modal";

const FFLSearch = ({ fulfillment, orderID, changeFFLAddress, setChangeFFLAddress, setRestrictionDisable, chooseDealer, setChooseDealer, setIsCalling, isCalling, GoToHandler, hasSelected, setHasSelected }) => {
  
  const [zipCode, setZipCode] = useState("");
  const [selectedFFLDealersID, setSelectedFFLDealersID] = useState("");
  const user = useSelector((state)=> state.userReducer)
  const [search, setSearch] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate()
  const [restrictionModal, setRestrictionModal] = useState(false);
  const [foidRestrictionModal, setFoidRestrictionModal] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [changeAddress, setChangeAddress] = useState(false);
  const records = useFFLDealers( zipCode, page, search);
  const restrictions = useRestrictions();
  const { orderFulfillmentID, shippingAddress } = fulfillment;
  const [pinChangeReq, setPinChangeReq] = useState(false);
  const showAddressCard = !(changeAddress || changeFFLAddress) && shippingAddress?.addressID?.length > 0;
  const showAddressPicker = (changeAddress || shippingAddress?.addressID?.length === 0 || changeFFLAddress) && !showAddress;
  const dispatch = useDispatch()
  const {
  CommonModule: { ListingPagination },
  } = useElementContext();
  const { t } = useTranslation();
  const { data, error, isFetching} = useAddFFLAddress(orderFulfillmentID, selectedFFLDealersID, orderID, false,true,true,true,false,'','',isCalling,setIsCalling,hasSelected);
  useEffect(()=>{
    if(changeAddress){
      setRestrictionDisable(true)
    }
    if(shippingAddress?.stateCode || shippingAddress?.postalCode){
      
      if(restrictions?.length && !changeAddress){
        for(const restriction in restrictions){
          // if(restrictions[restriction]?.foidFlag){
          //   if(restrictions[restriction]?.toBeFFLShipped){
          //   if(shippingAddress.stateCode === "IL"){
          //     if(!user?.foidNumber){
          //       setRestrictionDisable(true)
          //       setFoidRestrictionModal(true)
          //       return;
          //     } else if((new Date(user?.foidExpiryDate)).valueOf() < (new Date()).valueOf()){
          //       setRestrictionDisable(true)
          //       setFoidRestrictionModal(true)
          //       return;
          //     } else{
          //       setRestrictionDisable(false)
          //     }
          //   }
          //   }
          // }
          
          if(restrictions[restriction]?.restrictedStates?.length){
            // for(const orderItem in orderItems){
            //   if(orderItems[orderItem]?.orderItemID === restrictions[restriction]?.orderItemID){
                
            //   }
            // }
            for(const fulfillmentItem in fulfillment?.orderFulfillmentItems){
              if(fulfillment?.orderFulfillmentItems[fulfillmentItem]?.orderItemID === restrictions[restriction]?.orderItemID){
                if(restrictions[restriction]?.restrictedStates.includes(shippingAddress?.stateCode)){
                  setRestrictionDisable(true)
                  setRestrictionModal(true)
                  return;
                }else{
                  if(shippingAddress.stateCode === "IL" && !user?.foidNumber){
                    setRestrictionDisable(true)
                  }else{
                    setRestrictionDisable(false)
                  }
                }
              }
            }
          }
          if(restrictions[restriction]?.restrictedZipCodes?.length){
            for(const fulfillmentItem in fulfillment?.orderFulfillmentItems){
              if(fulfillment?.orderFulfillmentItems[fulfillmentItem]?.orderItemID === restrictions[restriction]?.orderItemID){
                if(restrictions[restriction]?.restrictedZipCodes.includes(shippingAddress?.postalCode)){
                  setRestrictionDisable(true)
                  setRestrictionModal(true)
                  return;
                }else{
                    setRestrictionDisable(false)
                }
              }
              
            }
          }
          
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[shippingAddress, user, restrictions, changeAddress])
  
  
  useEffect(()=>{
    if(shippingAddress?.stateCode || shippingAddress?.postalCode){
      
      if(restrictions?.length && !changeAddress){
        for(const restriction in restrictions){
          if(restrictions[restriction]?.foidFlag){
            if(restrictions[restriction]?.toBeFFLShipped){
            if(shippingAddress.stateCode === "IL"){
              if(!user?.foidNumber){
                setRestrictionDisable(true)
                setFoidRestrictionModal(true)
                return;
              } else if((new Date(user?.foidExpiryDate)).valueOf() < (new Date()).valueOf()){
                setRestrictionDisable(true)
                setFoidRestrictionModal(true)
                return;
              } else{
                setRestrictionDisable(false)
              }
            }else{
              setRestrictionDisable(false)
            }
            }
          }
        }
      }
    }
    
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[shippingAddress, restrictions])
  
  useEffect(() => {
    if( data?.errors && Object.keys(data?.errors).length !== 0 && !isFetching){
      setPinChangeReq(true);
    }
    else{
      setPinChangeReq(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data])
  
  useEffect(() => {
    // Check for the error after the component has been rendered for 2 seconds
    const checkForErrorTimeout = setTimeout(() => {
      if (error.isError === true) {
        toast.error(error.message);
      }
    }, 2000); // 2 seconds after the component is rendered
    // Cleanup the timeout to prevent it from running if the component unmounts
    return () => clearTimeout(checkForErrorTimeout);
  }, [error]);
  
  return (
    <>
    {showAddressCard && (<div className="fulfilmentAddressSelector">
    <hr/>
      <h5 className="h5 pt-1 pb-2 mb-3 border-bottom">FFL Dealer selected:</h5>
        <div className="row ">
          <div className="bg-lightgray rounded mb-2 col-md-4 p-3" key={shippingAddress?.addressID}>
            <div>
              <b>{shippingAddress.name}</b>
              <br />
              {shippingAddress.streetAddress} <br />
              {`${shippingAddress.city}, ${shippingAddress.stateCode} ${shippingAddress.postalCode}`} <br />
            </div>
            <hr />
            <button
              className="btn btn-link p-0 text-danger"
              type="button"
              disabled={false}
              onClick={(event) => {
                event.preventDefault();
                setChangeAddress(true);
                setChangeFFLAddress(true);
              }}
            >
              <i className="bi bi-times-circle"></i>
              <span className="small"> {t(`frontend.core.changeSelection`)}</span>
            </button>
          </div>
        </div>
      </div>
      )}
      { showAddressPicker && !showAddress && <div className="mt-4">
      <hr/>
          <div className="m-1">
            <h5 className="h5 pt-1 pb-2 border-bottom">Choose the FFL Dealers in Your Area:</h5>
          </div>
          <div className="card-body">
            <form> 
              <div className="input-group input-group-lg rounded-pill">
                <input
                  disabled={false}
                  className="form-control appended-form-control rounded-pill"
                  type="text"
                  placeholder="Zip code"
                  value={zipCode}
                  required
                  onChange={(e) => {setZipCode(e.target.value); setSearch(false)}}
                />
                <span className="input-group-append">
                  <button
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      setSearch(true);
                    }}
                    type="submit"
                  >
                    Search
                  </button>
                </span>
              </div>
           </form>
          </div>
          { Object.keys(records).length !== 0 && <FFLDealersList records={records} onSelect={(value)=>{setIsCalling(true);setSelectedFFLDealersID(value);setShowAddress(false); setChangeAddress(false); setChangeFFLAddress(false);setHasSelected(!hasSelected)}}/>}
          { Object.keys(records).length !== 0 && <ListingPagination
            recordsCount="10"
            currentPage={records.fflDealersRecords.currentPage}
            totalPages={records.fflDealersRecords.totalPages}
            setPage={setPage}
          />}
      </div>}
      <Modal show={restrictionModal} setShow={setRestrictionModal} title="Order Contains Restricted Products" backgroundstatic={true}>
      <h5>Due to federal or local laws some of these products are prohibited from shipping to your location.</h5>
      <h5>Please feel free to reach out to AmmoPlanet at info@ammoplanet.com</h5>
      <div className="d-flex flex-wrap mb-2 mb-sm-0 justify-content-around mt-3">
      <button className="btn btn-primary" onClick={()=>navigate("/shopping-cart")}>
        Return to Cart
      </button>
      <button className="btn btn-primary" onClick={(event)=>{
        event.preventDefault()
        setRestrictionModal(false)
        setChangeAddress(true)
      }}>
        Change Address
      </button>
      </div>
    </Modal>
    <Modal show={pinChangeReq} setShow={setPinChangeReq} title="Please choose another dealer" backgroundstatic={true}>
      <h5>Due to federal or local laws some of these products are prohibited from shipping to the chosen FFL dealers Zipcode</h5>
      <div className="d-flex flex-wrap mb-2 mb-sm-0 justify-content-around mt-3">
        <button className="btn btn-primary" onClick={()=>{navigate("/checkout/shipping");setPinChangeReq(false); setChangeFFLAddress(true); setChooseDealer(true);GoToHandler("ffl-ref-shipping");}}>
        Change Zipcode
      </button>
      </div>
    </Modal>
    {!user?.calculatedGuestAccountFlag && 
    <Modal show={foidRestrictionModal} setShow={setFoidRestrictionModal} title="Foid Restrictions Applied for products" backgroundstatic={true}>
      <h5>Please update your FOID details in the profile page and get it verified.</h5>
      <div className="d-flex flex-wrap mb-2 mb-sm-0 justify-content-around mt-3">
      <button className="btn btn-primary" onClick={()=>navigate("/my-account/profile")}>
        Update FOID
      </button>
      </div>
    </Modal>
    }
    {user?.calculatedGuestAccountFlag && 
    <Modal show={foidRestrictionModal} setShow={setFoidRestrictionModal} title="Foid Restrictions Applied for products" backgroundstatic={true}>
      <h5>Some products in your cart needs FOID details for the selected address. This cannot be done with a guest account.</h5>
      <h5>Please signup with an account or change the address</h5>
      <div className="d-flex flex-wrap mb-2 mb-sm-0 justify-content-around mt-3">
      <button className="btn btn-primary" onClick={async ()=>{
        await dispatch(logout(t("frontend.account.logout_success"), t("frontend.account.logout_failure"))).then(()=>{
          navigate("/register")
        })
        
      }}>
        Create an Account
      </button>
      <button className="btn btn-primary" onClick={(event)=>{
        event.preventDefault()
        setFoidRestrictionModal(false)
        setChangeAddress(true)
      }}>
        Change Address
      </button>
      </div>
    </Modal>
    }
    </>
  )
}
export { FFLSearch };
        