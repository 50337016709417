import {useEffect} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSocialLinks, isAuthenticated, useElementContext } from "@ultracommerce/react-storefront/global";
import { Helmet } from "react-helmet";
import './HeaderContent.css';

const HeaderTopLeft = ({ theme, siteSetting }) => {
  const {
    CommonModule: { SearchBar },
  } = useElementContext();
  const { Social } = useSocialLinks(siteSetting, theme.headerBarTextColour?.trim(), false);
  const PUBLIC_API_KEY = (process.env.REACT_APP_HOST_URL.includes("admin.ammoplanet.com")) ? 'Vrt5XX' : 'XtNt5U';

  return (
    <div className="col-md-6 d-none d-lg-flex d-flex align-items-center px-4">
      <Helmet>
        <script type="text/javascript" async="" src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${PUBLIC_API_KEY}`}></script>
      </Helmet>
      {(theme.headerStyleOption === "one" || theme.headerStyleOption === "four") && (
        <div className="d-none d-lg-flex align-items-center">
          <Social />
          <DisplayField fieldValue={siteSetting.companyPhone} iconClass={"telephone-fill"} />
          <DisplayField fieldValue={siteSetting.companyAddress} iconClass={"pin-map-fill"} />
        </div>
      )}
      {theme.headerStyleOption === "three" && (
        <div className="d-none d-lg-flex align-items-center my-1">
          <SearchBar searchBoxTypeaheadFlag redirectToSearchPage searchBoxPlaceholder={theme.searchPlaceholder} />
        </div>
      )}
    </div>
  );
};

const HeaderTopRight = ({ ribbons, theme, siteSetting }) => {
  const {
    CommonModule: { MiniCart },
  } = useElementContext();
  return (
    <div className="col-md-6 col-sm-12 d-flex justify-content-end">
      <div className="d-flex align-items-center">{ribbons}</div>
      <div className="text-black d-flex align-items-center" style={{ color: theme.headerBarTextColour?.trim() }}>
        {theme.headerStyleOption !== "one" &&
          theme.headerStyleOption !== "four" &&
          siteSetting.sitePurpose !== "Non-Transactional" && (
            <div className="ms-3 d-none d-lg-block">
              <MiniCart color={theme.headerBarTextColour?.trim()} />
            </div>
          )}
      </div>
    </div>
  );
};

const HeaderContentOne = ({ theme, siteSetting, filteredMenus = [] }) => {
  const {
    CommonModule: { MiniCart, SearchBar },
  } = useElementContext();
  return (
    <div className="d-flex flex-column align-items-end col-xl-8 order-xl-2">
      <div className="d-none d-lg-flex justify-content-end col-9">
        <SearchBar searchBoxTypeaheadFlag redirectToSearchPage searchBoxPlaceholder={theme.searchPlaceholder} />
        <div className="ms-2 d-none d-lg-block">{siteSetting.sitePurpose !== "Non-Transactional" && <MiniCart />}</div>
      </div>

      <div className="text-center text-md-start mb-3 mb-lg-0 order-xl-3 d-none d-lg-block">{filteredMenus}</div>
    </div>
  );
};

const HeaderContentTwo = ({ theme, siteSetting }) => {
  const { Social } = useSocialLinks(siteSetting, theme.headerTextColor?.trim(), false);
  return (
    <div className="d-none d-lg-flex flex-column align-items-start small order-xl-2">
      <div className="d-flex">
        <DisplayField fieldValue={siteSetting.companyPhone} iconClass={"telephone-fill"} />
        <Social />
      </div>
      <DisplayField fieldValue={siteSetting.companyEmailAddress} iconClass={"at"} />
      <DisplayField fieldValue={siteSetting.companyAddress} iconClass={"pin-map-fill"} />
    </div>
  );
};

const DisplayField = ({ fieldValue, iconClass }) => {
  return (
    <>
      {fieldValue && (
        <div className="me-2 d-flex align-items-center">
          <i className={`bi bi-${iconClass} ms-sm-3 me-2 fs-5`}></i>
          <span>{fieldValue}</span>
        </div>
      )}
    </>
  );
};

const MobileHeader = (siteSetting) => {
  const {
    CommonModule: { MiniCart},
  } = useElementContext();
  
  const accountBubbleRoute = isAuthenticated() ? "/my-account/overview" : "/my-account/login";

  return (
    <div className="ms-2 d-lg-none d-flex col-6 align-items-center justify-content-end px-0">
      <Link to={accountBubbleRoute} className="text-dark nav-align-padding px-4">
        <span className="nav-link text-center">
          <i className="bi bi-person-circle account-bubble-icon fs-4"></i>{" "}
        </span>
      </Link>
      {siteSetting?.sitePurpose === "Transactional" && (
        <Link to="/my-account/shopping-cart" className="text-dark">
          <span className="nav-link text-center">
            <i className="bi bi-cart fs-4"></i>{" "}
          </span>
        </Link>
      )}
      <div className="text-dark nav-align-padding px-4">
        <MiniCart mobileView={true}/>
      </div>
      <button
        className="nav-link collapsed align-items-end d-lg-none bg-transparent border-0"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i className="bi bi-list fs-4"></i>
      </button>
    </div>
  );
};

const MobileNav = ({ filteredMenus = [], megaMenus = [] }) => {
  const { t } = useTranslation();

  return (
    <div className="collapse navbar-collapse main-nav no-print" id="navbarCollapse">
      {/*  Mobile */}
      <div className="d-lg-none mobile-nav">
        <div className="mobile-nav-header">
          <h2 className="border-bottom">{t("frontend.nav.menu")}</h2>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="bi bi-x-circle"></i>
          </button>
        </div>
        {[...filteredMenus, ...megaMenus]}
      </div>
      {/*  default */}
      <div className="d-none d-lg-block desktop-nav">{megaMenus}</div>
    </div>
  );
};

const HeaderContentFour = ({ theme, siteSetting, filteredMenus = [] }) => {
  const { t } = useTranslation();
  const {
    CommonModule: { MiniCart, SearchBar },
  } = useElementContext();

  return (
    <div className="row col-12 align-items-center justify-content-between position-relative mb-2">
      <div className="d-flex filtered-block-div col-5 col-lg-3">
        <Link className="d-block" to="/">
          <span className="navbar-brand d-block">
            {theme && theme.logo ? (
              <img
                src={`${theme.logo?.trim()}`}
                className="img-fluid navbar-brandLogo"
                alt={t("frontend.logo")}
                style={{ maxHeight: "60px", minWidth: "150px" }}
              />
            ) : null}
          </span>
        </Link>
        {theme.headerTagLine && <div className="d-none d-lg-block">{theme.headerTagLine}</div>}
      </div>

      <div className="d-none d-lg-flex flex-column justify-content-center col-4">
        <div className="d-lg-flex m-auto">
          <SearchBar searchBoxTypeaheadFlag redirectToSearchPage searchBoxPlaceholder={theme?.searchPlaceholder} />
        </div>
      </div>

      <MobileHeader />

      <div className="ms-2 d-none d-lg-flex justify-content-end text-end col-4">
        {filteredMenus.length > 0 && <div>{filteredMenus}</div>}
        {siteSetting.sitePurpose !== "Non-Transactional" && <MiniCart />}
      </div>
    </div>
  );
};

const HeaderContent = ({ ribbons, megaMenus = [], filteredMenus = [], theme, siteSetting, selectedCartOrQuote }) => {
  const { t } = useTranslation();
  const {
    CommonModule: { MiniCart, SearchBar, AccountImpersonationBanner },
  } = useElementContext();

    const isAuth = isAuthenticated();
  
    useEffect(()=>{
    let e = document.getElementsByClassName("register-item-wrapper")[0];
    
    if (e?.style && isAuth) {
      e.style["display"] = "none";
    }
    if (e?.style && !isAuth) {
      e.style["display"] = "block";
    }  
    },[isAuth])
  
  return (
    <>
      <AccountImpersonationBanner />
      <div
        className="header-ribbon container px-4 no-print"
        style={{
          backgroundColor: theme.headerBarColor?.trim(),
          color: theme.headerBarTextColour?.trim(),
        }}
      >
        <div className="row justify-content-evenly">
          <HeaderTopLeft theme={theme} siteSetting={siteSetting} />
          <HeaderTopRight theme={theme} siteSetting={siteSetting} ribbons={ribbons} />
        </div>
      </div>
      <nav className="py-3 no-print main-nav">
        <div className="container">
          {theme.headerStyleOption === "four" ? (
            <HeaderContentFour theme={theme} siteSetting={siteSetting} filteredMenus={filteredMenus} />
          ) : (
            <div className="position-relative d-flex justify-content-between align-items-center">
              <div
                className={`row col-12 text-center text-md-start mb-3 mb-lg-0 order-xl-1 justify-content-between ${
                  theme.headerStyleOption === "two" || theme.headerStyleOption === "four"
                    ? "col-xl-9 col-lg-9"
                    : "col-xl-4 col-lg-4"
                }`}
              >
                <div className="col-5 col-lg-6 d-flex filtered-block-div">
                  <Link className="d-block" to="/">
                    <span className="navbar-brand d-block">
                      {theme && theme.logo ? (
                        <img
                          src={`${theme.logo?.trim()}`}
                          className="img-fluid navbar-brandLogo"
                          alt={t("frontend.logo")}
                          style={{ maxHeight: "60px", minWidth: "150px" }}
                        />
                      ) : null}
                    </span>
                  </Link>
                  {theme.headerStyleOption !== "two" && (
                    <div className="d-xl-flex d-none col-xl-6 flex-wrap">{theme.headerTagLine}</div>
                  )}
                  {theme.headerStyleOption === "two" && (
                    <div className="d-flex flex-column justify-content-center">
                      <div className="d-xl-flex d-none">{theme.headerTagLine}</div>
                      <div className="d-none d-lg-flex">
                        <SearchBar
                          searchBoxTypeaheadFlag
                          redirectToSearchPage
                          searchBoxPlaceholder={theme.searchPlaceholder}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <MobileHeader />
              </div>
              {theme.headerStyleOption === "one" && (
                <HeaderContentOne
                  theme={theme}
                  selectedCartOrQuote={selectedCartOrQuote}
                  siteSetting={siteSetting}
                  filteredMenus={filteredMenus}
                />
              )}
              {theme.headerStyleOption !== "one" && <HeaderContentTwo theme={theme} siteSetting={siteSetting} />}
            </div>
          )}
          <div className="d-block d-lg-none col-12">
            <div className="">
              <SearchBar searchBoxTypeaheadFlag redirectToSearchPage searchBoxPlaceholder={theme?.searchPlaceholder} />
            </div>
          </div>
        </div>
      </nav>
      <MobileNav
        theme={theme}
        filteredMenus={filteredMenus}
        megaMenus={megaMenus}
        SearchBar={SearchBar}
        MiniCart={MiniCart}
      />
    </>
  );
};

export { HeaderContent };
