import View from "./ProductDetailsPage.pageView";

import { useComponentModel } from "@ultracommerce/react-storefront/global/src/pages/ProductDetailsPage/ProductDetailsPage.pageService";
import {
  ProductContextProvider,
  useServiceContext,
} from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { useEffect } from "react";

const ProductDetailPage = () => {
  const {
    PageModule: { DynamicPage },
    CommonModule: { PageContentRow },
  } = useElementContext();
  return (
    <DynamicPage>
      {({ renderDynamicContent }: any) => (
        <>
          <PageContentRow>
            <ProductContextProvider>
              <ProductDetailPageView />
            </ProductContextProvider>
          </PageContentRow>
          {renderDynamicContent()}
        </>
      )}
    </DynamicPage>
  );
};

const ProductDetailPageView = () => {
  const { componentData } = useComponentModel();
  // Add config override for product detail here
  const { LogService } = useServiceContext();
  useEffect(() => {
    new LogService().viewItem([componentData.product]);
  }, [LogService, componentData.product]);

  return <View {...componentData} />;
};

export default ProductDetailPage;
