import { ProductCardActionsModel } from "@ultracommerce/react-storefront/src/modules/ProductCard/Components/ProductCardActions/ProductCardActions.componentModel";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { Sku } from "@ultracommerce/react-storefront/src/interface/Product";
import { SearchProduct, SearchSku } from "@ultracommerce/react-storefront/src/interface/SearchProduct";

const DropdownButtons = ({
  buttonStack,
  ...rest
}: Omit<ProductCardActionsModel, "type"> & { onAddToCart: () => void }) => {
  
  const { ProductCardModule } = useElementContext();
  
  const validateProductOutOfStock = (sku?: Sku | SearchSku | SearchProduct) => {
  return !sku || sku?.calculatedQATS < 1;
  };
  
  const [button, ...buttons] = buttonStack;
  const { sku, isFetching } = rest;
  const isProductOutOfStock =  !isFetching && validateProductOutOfStock(sku);
  let disableListingButton = false
  if(isProductOutOfStock === true){
      disableListingButton = true
  }else{
    disableListingButton = false
  }
  return (
    <div className="accordion accordion-cart" id="accordioncartExample">
      <div className="accordion-item quote-input-box d-flex">
        <ProductCardModule.CoreControl.ProductCardButton {...rest} {...button} disableListingButton={disableListingButton}/>
        {buttons.length > 0 && (
          <>
            <div
              id={sku.skuCode}
              className="accordion-collapse accordion-absolute collapse"
              aria-labelledby="cart-headingOne"
              data-bs-parent="#accordioncartExample"
            >
              <div className="accordion-body">
                {buttons.map((buttonProps: any, idx: number) => {
                if(isProductOutOfStock){
                  buttonProps.disableListingButton = true
                }else{
                  buttonProps.disableListingButton = false
                }
                return(
                  <div key={idx} className="pt-2">
                    <ProductCardModule.CoreControl.ProductCardButton {...rest} {...buttonProps} />
                  </div>
                )})}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { DropdownButtons };
