import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import {
  content,
  authReducer,
  userReducer,
  cart,
  configuration,
  subscriptionCart,
  cartQuoteReducer,
  allCartQuote,
} from "@ultracommerce/react-storefront/global";
import devData from "./preload";
import { combineReducers } from "redux";
import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});
const rootReducer = combineReducers({
  content,
  authReducer,
  userReducer,
  cart,
  configuration,
  subscriptionCart,
  cartQuoteReducer,
  allCartQuote,
});

const preloadedState = { configuration: devData };
// preloadedState.preload = {...devData,...preloadedState.preload}
// Allow the passed state to be garbage-collected
const store = createStore(
  rootReducer,
  preloadedState,
  composeWithDevTools(applyMiddleware(thunkMiddleware), sentryReduxEnhancer)
);

export const createReduxStore = ({ logService }) => {
  const thunk = thunkMiddleware.withExtraArgument({ logService });
  return createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(thunk), sentryReduxEnhancer));
};

export default store;
