import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { useContentContext, useElementContext } from "@ultracommerce/react-storefront/global";

const ProductType = () => {
  const {
    ProductListingModule,
    ProductModule,
    PageModule: { DynamicPage },
    CommonModule: { PageContentRow, ListingBanner },
  } = useElementContext();
  let { pathname } = useLocation();
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const productTypeBase = useSelector((state) => state.configuration.filtering.productTypeBase);
  const { id } = useParams();
  const crumbCalculator = () => {
    return productTypeData?.ancestors?.filter(ancestor => ancestor.urlTitle !== productTypeBase).map((ancestor, idx) => ({
      title: ancestor.productTypeName,
      urlTitle: productTypeData?.ancestors.slice(1, idx + 2).map(c => c.urlTitle).join('/')
    }))
  }

  const [productTypeData, setProductTypeData] = useState()
  const [isFetching, setIsFetching] = useState(false)
  useEffect(() => {
    if(!isFetching) {
      setIsFetching(true)
      setProductTypeData({})
      fetch(`${process.env.REACT_APP_DELTA_STORE_URL}/public/ultracommerce/product-type/transform/byUrlTitle/merchandise/${pathname.replace('/product-type/', '').replace('/range/', '')}`).then(resp => resp.json()).then(data => {
        setIsFetching(false)
        setIsError(false)
        setErrorMessage()
        setProductTypeData({
          ...data,
          childProductTypes: data.children.map(child => ({
            ...child,
            urlTitle: child.productTypeUrlTitlePath.replace('merchandise/', '')
          }))
        })
      }).catch(e => {
        setIsFetching(false)
        setIsError(true)
        setErrorMessage("Error finding our products")
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const pageData = useContentContext();

  const {
    productTypeSearchListing,
    productListingSettings,
    rangeTilesSettings,
    rangeHeaderStyle = "compact",
  } = pageData.templateData || {};
  if (!productTypeSearchListing) return <DynamicPage />;

  const { setting: { productHTMLTitleString } = {} } = {};

  const title = pageData?.title || productTypeData?.productTypeName;
  const pageLogo = pageData?.pageLogo || productTypeData?.imageFile;
  const description = pageData?.description || productTypeData?.productTypeDescription;
  return (
    <DynamicPage>
      {({ renderDynamicContent }) => (
        <>
          {productHTMLTitleString && <Helmet title={productHTMLTitleString} />}
          <PageContentRow>
            <ListingBanner
              crumbs={crumbCalculator()}
              heading={title}
              images={[pageLogo]}
              description={description}
              type="productType"
              isCollapsible={rangeHeaderStyle === "compact"}
            />
          </PageContentRow>

          {isError && (
            <div className="container bg-light box-shadow-lg rounded-lg p-5">
              <div className="row">
                <div className="alert alert-info" role="alert">
                  {errorMessage}
                </div>
              </div>
            </div>
          )}

          {renderDynamicContent()}

          {rangeTilesSettings !== "hideRangeTiles" && productTypeData?.childProductTypes?.length > 0 && (
            <ProductModule.CoreComponents.ProductTypeList
              showOnlyDirectChild={rangeTilesSettings === "showOnlyDirectChild"}
              isFetching={isFetching}
              data={productTypeData}
            />
          )}

          {(!isFetching && (productListingSettings !== "hideProductListing" && productTypeData?.childProductTypes?.length === 0)) && (
            <ProductListingModule.CoreComponents.SearchListing
              key={id}
              hide="productType"
              initPreFilter={{
                facet_productType: pathname,
                ...(productListingSettings === "showOnlyParentProducts" ? { includeParentProductsOnly: true } : {}),
              }}
              {...productTypeSearchListing}
            />
          )}
        </>
      )}
    </DynamicPage>
  );
};

export default ProductType;